import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { brandSort, conditionSort, formatMonths, generateGradientColors } from "../../../../utils/chartUtils.js";

import { useTheme } from "../../../../utils/ThemeManager.jsx";

import "./UniverseCharts.css";
import { formatFloat } from "../../../../utils/tableUtils.js";

/*
Condition Journey
- Preventative
- Symptoms
- Diagnosis
- Treatment
- Support
- Clinical Trials
- General

Branded Journey
- Evaluation
- Payment
- Assistance
- Usage
- Side Effects
- General
*/

const UniverseCharts = ({ data, category_type }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({
    pie: {},
    bar: {},
  });

  const category_color_grades = {
    "Branded": 6,
    "Condition": 7,
  }

  useEffect(() => {
    if (data == null || Object.keys(data).length === 0) return;

    const colors = theme === 'dark' 
      ? generateGradientColors('#FCFCFC', '#020021', category_color_grades[category_type])
      : generateGradientColors('#C4D6EB', '#0561B7', category_color_grades[category_type]);

    setOptions({
      pie: pieOptions(data, category_type, colors),
      bar: barOptions(data, category_type, colors),
    });
  }, [theme]);


  if (data == null || Object.keys(data).length === 0) {
    return (
      <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">
        Keyword Universe data is not available.
      </p>
    );
  }

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-2">
        <ChartDashboard config={options.pie} id={`keyword-universe-${category_type}-pie-container`} />
      </div>
      <div className="col-span-4">
        <ChartDashboard config={options.bar} id={`keyword-universe-${category_type}-bar-container`} />
      </div>
    </div>
  );
};

const sortCategories = (categories, category_type) => {
  if (category_type === "Branded") {
    return categories.sort((a, b) => brandSort[a] - brandSort[b]);
  } else if (category_type === "Condition") {
    return categories.sort((a, b) => conditionSort[a] - conditionSort[b]);
  }
  return categories;
};

export default UniverseCharts;

const pieOptions = (data, category_type, colors) => {
  // get names of all categories of the same type
  let categories = Object.entries(data)
    .filter(([key, value]) => value.category_type === category_type)
    .map(([key]) => key)


  categories = sortCategories(categories, category_type);

  // pie chart
  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.search_volume_avg_annual_total || 0);
  }, 0);

  const universePie = categories.map((category) => {
    const cost = data[category]?.search_volume_avg_annual_total || 0;

    return {
      name: category,
      y: (cost / totalCost) * 100,
      visible: cost > 1,
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${category_type}-keywords-pie-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${category_type}-keywords-pie-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            styledMode: false,
            type: "pie",
            height: 450,
          },
          title: {
            text: `${category_type} Average Search Volume by Category`,
          },
          tooltip: {
            enabled: true,
            stickOnContact: true,
            formatter: function () {
              const colorDot = `<span style="color:${this.point.color};">●</span>`;
              return `${colorDot} <b>${
                this.point.name
              }</b>: ${Highcharts.numberFormat(this.point.percentage, 1)}%`;
            },
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: [
                {
                  distance: -60,
                  enabled: true,
                  formatter: function () {
                    if (this.point.visible && this.percentage > 0.5) {
                      return `${Highcharts.numberFormat(
                        this.point.percentage,
                        1
                      )}%`;
                    }
                  },
                  style: {
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: 'contrast',
                    textOutline: "1px solid contrast",
                  },
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: universePie,
            },
          ],
        },
      },
    ],
  };
};

const barOptions = (data, category_type, colors) => {
  // get names of all categories of the same type
  const categories = Object.entries(data)
    .filter(([key, value]) => value.category_type === category_type)
    .map(([key]) => key)
    .sort((a, b) =>
      category_type === "Branded"
        ? brandSort[a] - brandSort[b]
        : conditionSort[a] - conditionSort[b]
    );

  // stacked bar chart
  const universeBar = categories.map((category) => {
    return {
      name: category,
      data: Array.from({ length: 12 }, (_, monthIndex) => {
        return data?.[category]?.search_volume_monthly_totals?.[monthIndex] || 0;
      }),
      color: colors[categories.indexOf(category)],
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${category_type}-keywords-stacked-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${category_type}-keywords-stacked-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            styledMode: false,
            type: "column",
            height: 450,
          },
          sync: {
            highlight: true,
          },
          title: {
            text: null,
          },
          xAxis: {
            // use the first object of the data object
            categories: formatMonths(
              data[Object.keys(data)[0]].search_volume_months
            ),
            stackLabels: {
              enabled: true,
              textOutline: "none",
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatCurrency(this.total, 0);
              },
              style: {
                fontSize: "0.3rem",
              }
            },
          },
          yAxis: {
            title: {
              text: "Average Search Volume",
            },
            min: 0,
            stackLabels: {
              enabled: true,
              textOutline: "none",
              formatter: function () {
                // dollar sign and 2 decimal places
                return formatFloat(this.total, 0);
              },
              style: {
                textOutline: "none",
              }
            },
          },
          legend: {
            itemStyle: {
              fontSize: "14px",
            }
          },
          tooltip: {
            formatter: function () {
              console.log(this.point)
              return formatBarChartTooltip(this.point, colors);
            },
          },
          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
          series: universeBar,
        },
      },
    ],
  };
};

// tooltip pointFormatter function
export const formatBarChartTooltip = function(point, colors) {
  const colorIndex = point.colorIndex || 0;
  const color = colors[colorIndex];
  const coloredDot = `<span style="color:${color};">● </span>`;
  const formattedOriginalValue = Highcharts.numberFormat(point.y, 0);

  return `${coloredDot}<b> Search Volume:</b> ${formattedOriginalValue}`;
};