import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import spinner from '../../../../assets/img/spinner.svg'

import './InfoBox.css';

function InfoBox({ icon, title, value, loading, to, project }) {
  const ContentTag = to ? Link : 'p';
  
  return (
    <div className={`info-box ${ to ? 'link' : ''}`}>
      <FontAwesomeIcon
        className="hidden md:block"
        icon={icon}
        size="2xl"
      />
      <div className="content">
        <h3>{title}</h3>
        {
          loading 
            ? <img src={spinner} alt="Loading..." className="h-8 w-8" />
            : <ContentTag className="text-center md:text-right" to={ to } state={{ project: project }}>
                {value}
              </ContentTag>
        }
      </div>
    </div>
  );
}

export default InfoBox;