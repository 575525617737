import { useState, useEffect } from "react";
import { $axios } from "../../../../services/axiosInstance";

const useKeywordOptions = () => {
  const [projects, setProjects] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [branded_categories, setBrandedCategories] = useState([]);
  const [unbranded_categories, setUnbrandedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeywordOptions = async () => {
      try {
        const { data } = await $axios.get('/derived_keywords_options/')
        setProjects(data.projects);
        setConditions(data.conditions);
        setBrandedCategories(data.branded_categories);
        setUnbrandedCategories(data.unbranded_categories);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKeywordOptions();
  }, []);

  return { 
    projects, 
    conditions, 
    branded_categories, 
    unbranded_categories, 
    loading, 
    error,
  };
}

export default useKeywordOptions;