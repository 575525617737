import { useState } from "react";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// styles
import './KeywordItem.css';

const KeywordItem = ({ keyword, onRemove, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    setIsFocused(false);
  }

  return (
    <div className={`keyword-item ${isHovered ? 'hovered' : ''} ${isFocused ? 'focused' : ''}`}>
      <span>{keyword}</span>

      { onRemove && (
        <button 
          type="button" 
          className="remove-btn" 
          onClick={() => onRemove()} 
          disabled={disabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-label={`Remove ${keyword}`}
          aria-disabled={disabled}
          data-testid={`remove-${keyword}`}
        >
          <FontAwesomeIcon icon={faTimes} size="xs" />
          <span className="sr-only">Remove { keyword }</span>
        </button>
      )}
    </div>
  );
}

export default KeywordItem;
