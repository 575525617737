import React, { useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

import "./Lightbox.css";

function Lightbox({ imageSrc, title, summary, setIsVisible, isVisible }) {
  const lightboxRef = useRef(null);
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      const focusableElements = lightboxRef.current.querySelectorAll(
        "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
      );
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const handleTabKey = (e) => {
        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      const handleKeyDown = (e) => {
        if (e.key === "Escape") {
          setIsVisible();
        }
      };

      document.addEventListener("keydown", handleTabKey);
      document.addEventListener("keydown", handleKeyDown);
      firstElement.focus();

      return () => {
        document.removeEventListener("keydown", handleTabKey);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isVisible, setIsVisible]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageSrc;
    link.target = "_blank";
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!isVisible) return null;

  return (
    <div
      ref={lightboxRef}
      className='lightbox-backdrop'
      onClick={() => setIsVisible()}
      data-testid="lightbox-backdrop"
    >
      <div className="toolbar">
        <div className="space-x-8" onClick={(e) => e.stopPropagation()}>
          <button onClick={handleDownload} ref={closeButtonRef}>
            <FontAwesomeIcon icon={faFileArrowDown} />
            <span className="sr-only">Download</span>
          </button>
          <button onClick={() => setIsVisible()}>
            <FontAwesomeIcon icon={faXmark} />
            <span className="sr-only">Close</span>
          </button>
        </div>
      </div>

      <div className="content">
        <h2 className="section-header">{title}</h2>
        <img className="" src={imageSrc} alt="" onClick={(e) => e.stopPropagation()} />

        <div className="summary" onClick={(e) => e.stopPropagation()}>
          <p>{summary}</p>
        </div>
      </div>
    </div>
  );
}

export default Lightbox;
