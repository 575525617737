import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function DynamicCrumbs({ current }) {
  const location = useLocation();
  const history = location.state?.history || [];

  if (!history || history.length === 0) {
    return null;
  }

  return (
    <nav aria-label="breadcrumbs">
      <ol className="flex gap-2 text-xs md:text-sm items-center">
        {history.map((breadcrumb, index) => (
          <li key={index} className="flex items-center">
            <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>
            <FontAwesomeIcon
              className="ml-2 text-black dark:text-white"
              icon={faChevronRight}
              aria-hidden="true"
            />
          </li>
        ))}
        <li className="font-bold text-watermelon-500">
          {current}
        </li>
      </ol>
    </nav>
  );
}

export default DynamicCrumbs;
