import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { formatFloat, formatCurrency, formatPercent } from "../../../../utils/tableUtils.js";

import "./KeywordsTable.css";

function KeywordsTable({ type, keywords }) {
  const [sortConfig, setSortConfig] = useState({ key: 'value', direction: 'ascending' });

  // Sorting function
  const sortedKeywords = [...keywords].sort((a, b) => {
    if (sortConfig.key === 'value') {
      // Sort by Keyword (Alphabetically)
      const aValue = a.value.toLowerCase();
      const bValue = b.value.toLowerCase();
      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    } else {
      // Sort by numerical values for Monthly Volume, KW Difficulty, Average CPC
      const aValue = a.latest_monthly_data[sortConfig.key] || 0;
      const bValue = b.latest_monthly_data[sortConfig.key] || 0;
      return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
    }
  });

  // Function to handle sorting toggling
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' 
      ? <FontAwesomeIcon icon={faCaretUp} />
      : <FontAwesomeIcon icon={faCaretDown} />;
    }
    return null;
  };

  return (
    <table className="keyword-universe-table">
      <thead className="bg-[#F5F5F5] bg-opacity-100 dark:bg-[#0561B7] dark:bg-opacity-10 sticky top-0">
        <tr>
          <th className="cursor-pointer text-left whitespace-nowrap select-none" onClick={() => requestSort('value')}>
            Keyword <span className="ml-2">{getSortArrow('value')}</span>
          </th>
          <th className="text-center">Type</th>
          <th className="text-center">{ type === 'Brand Project' ? "Brand" : "Condition" }</th>
          <th className="text-center">Category</th>
          <th className="text-center">Intent</th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" onClick={() => requestSort('search_volume_avg')}>
            Monthly Volume <span className="ml-2">{getSortArrow('search_volume_avg')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" onClick={() => requestSort('keyword_difficulty')}>
            KW Difficulty <span className="ml-2">{getSortArrow('keyword_difficulty')}</span>
          </th>
          <th className="cursor-pointer text-center whitespace-nowrap select-none" onClick={() => requestSort('google_ads_cpc_avg')}>
            Average CPC <span className="ml-2">{getSortArrow('google_ads_cpc_avg')}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedKeywords.map((keyword, index) => (
          <tr className="odd:bg-deep-space odd:bg-opacity-10 odd:dark:bg-white odd:dark:bg-opacity-10" key={index}>
            <td>
              <Link to={`/admin/keyword-profile/${keyword.uuid}`}>
                {keyword.value}
              </Link>
            </td>
            <td className="text-center">{keyword.keyword_type}</td>
            <td className="text-center">{type === 'Brand Project' ? keyword.projects__name || "-" : keyword.condition_analysis__name || '-'}</td>
            <td className="text-center">{keyword.categories__name}</td>
            <td className="text-center">{keyword.intent || "-"}</td>
            <td className="text-center">{formatFloat(keyword.latest_monthly_data.search_volume_avg, 0) || "-"}</td>
            <td className="text-center">{formatPercent(keyword.latest_monthly_data.keyword_difficulty, 0) || "-"}</td>
            <td className="text-center">{formatCurrency(keyword.latest_monthly_data.google_ads_cpc_avg, 2) || "-"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default KeywordsTable;
