import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { useTheme } from "../../../../utils/ThemeManager.jsx";
import { generateGradientColors } from "../../../../utils/chartUtils.js";

// data: brandData.side_effect_plot
const TolerabilityChart = ({ project, data }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});


  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    setOptions(pieOptions(project, data, theme));
  }, [theme]);

  if (!data || !Object.keys(data).length) {
    return <p className="px-4 py-8 font-bold grid place-content-center">Tolerability data is not available.</p>
  }

  return <ChartDashboard config={options} id="tolerability-chart-container" />;
};

export default TolerabilityChart;

// chart options
const pieOptions = (project, data, theme) => {
  const keys = Object.keys(data);
  const values = Object.values(data);
  const percentages = values.map((value) => value / values.reduce((a, b) => a + b, 0) * 100);

  const colors = {
    dark: generateGradientColors('#020021', '#FCFCFC', keys.length),
    light: generateGradientColors('#0561B7', '#C4D6EB', keys.length),
  };

  return {
  gui: {
    layouts: [
      {
        rows: [{
          cells: [{
            id: `tolerability-cell-0`,
          }]
        }]
      }
    ],
  },
  components: [
    {
      type: "Highcharts",
      cell: `tolerability-cell-0`,
      chartOptions: {
        colors: colors[theme],
        chart: {
          height: 484,
          styledMode: false,
          type: 'pie',
      },
        title: {
          text: 'Proportion of Side Effects vs. Brand',
        },
        subtitle: {
          text: percentages[1] 
            ? `BRAND Side Effects makes up ${ percentages[1].toFixed(2) }% of searches when compared to the volume of the brand name, ${ project.name }.`
            : 'No data available',
        },
        tooltip: {
          enabled: false
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              distance: -60,
              enabled: true,
              format: '{point.percentage:.2f}%',
              style: {
                color: 'contrast',
                fontSize: '1.4rem',
              }
            }
          }
        },
        series: [
          {
            name: 'Search Volume',
            type: 'pie',
            data: [
              {
                name: keys[0] || 'Medication',
                y: percentages[0] || 0,
              },
              {
                name: keys[1] || 'Side Effect',
                y: percentages[1] || 0,
              },
            ],
          },
        ],
      }
    }
  ]
  }
}