import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { brandSort, generateGradientColors } from "../../../../utils/chartUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

import './ConversionFunnel.css';

// data: project.keyword_universe
function ConversionFunnel({ data }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if (!data || !Object.keys(data).length) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Conversion funnel data is not available.</p>
  };

  return <div className="conversion-funnel">
    <div className="content">
      <div className="col col-1">
        <ChartDashboard config={options} id="conversion-funnel-container" />
      </div>
    </div>
  </div>
} 

export default ConversionFunnel;

const chartOptions = (data, theme) => {
  // Collect all objects with a category_type of "Branded"
  const brandedSeriesKeys = Object.entries(data)
    .filter(([key, value]) => value.category_type === "Branded")
    .sort((a, b) => brandSort[a[0]] - brandSort[b[0]]);

  // Generate dynamic series for all "Branded" categories
  const dynamicSeries = brandedSeriesKeys.map(([key, value]) => ({
    name: key,
    data: [
      value.estimated_visits || 0,
      value.estimated_tass_starts || 0,
      value.estimated_tass_completes || 0,
      value.estimated_tass_ok || 0,
      value.estimated_purchases || 0,
    ],
  }));

  const colors = theme === 'dark' 
  ? generateGradientColors('#D8DAE0', '#020021', brandedSeriesKeys.length)
  : generateGradientColors('#0561B7', '#C4D6EB', brandedSeriesKeys.length);

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `conversion-funnel-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `conversion-funnel-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            type: 'bar',
            height: 450,
            styledMode: false,
          },
          title: {
            text: 'ACNU Conversion Funnel',
            align: 'left',
          },
          subtitle: {
            text: `There were ${ data['Grand Total'].search_volume_avg_annual_total.toLocaleString() } searches.`,
            align: 'left',
          },
          xAxis: {
            categories: ['Site Visits', 'Complete "Take Quiz"', 'Complete "Get Result"', 'Complete "Ok to Use"', 'Set Up Account & Purchase'],
            title: {
              text: null
            },
            stackLabels: {
              enabled: true,
            }
          },
          yAxis: {
            maxPadding: 0.1,
            min: 0,
            title: {
              text: null
            },

            stackLabels: {
              enabled: true,
              style: {
                color: theme === 'dark' ? '#3AE500' : '#020021',
              },
              formatter: function () {
                  return Highcharts.numberFormat(this.total, 0, '.', ',');
              }
            }
          },
          tooltip: {
            useHTML: true,
            shadow: false,
            headerFormat: '<table>',
            pointFormat: '<tr><th>{point.category}</th></tr>' +
                         '<tr><th>Passengers:</th><td>{point.y}</td></tr>',
            footerFormat: '</table>'
          },
          legend: {
            verticalAlign: 'bottom',
            margin: 20,
        },
          plotOptions: {
            bar: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
              }
            },
            column: {

            }
          },
          series: dynamicSeries,
        }
      },
    ],
  };
};