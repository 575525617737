function ErrorDisplay({ error }) {
  return (
    <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
      <div className="space-y-4">
        <h1 className="text-2xl">Error fetching data</h1>
        <p className="text-red-500 font-mono">
          {error?.message || "Something went wrong."}
        </p>
      </div>
    </section>
  );
}

export default ErrorDisplay;