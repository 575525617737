import { createContext, useContext, useEffect } from "react";
import { GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase'

import useSessionStore from '../stores/session.js'

const googleProvider = new GoogleAuthProvider();

const UserContext = createContext();

const UserAuth = () => {
  return useContext(UserContext);
};

const AuthContextProvider = ({ children }) => {
  const user = useSessionStore(state => state.user)
  const setUser = useSessionStore(state => state.setUser)

  const signInWithGoogle = () => {
      return signInWithPopup(auth, googleProvider);
  }

  const logout = () => {
    return signOut(auth)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser({});
      }
    });

    return () => unsubscribe();
  }, [setUser]);

  return (
    <UserContext.Provider value={{ user, setUser, signInWithGoogle, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserAuth, AuthContextProvider, UserContext }