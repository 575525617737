// a loading spinner overlay component
import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading" data-testid="loading" aria-busy="true">
      <div className="spinner" aria-label="Loading..."></div>
    </div>
  )
}

export default Loading;