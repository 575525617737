import { useState } from "react";
import { Link } from "react-router-dom";

import { $axios } from "../../../services/axiosInstance";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// global components
import KeywordField from "../../../components/KeywordField/KeywordField";
import Loading from "../../../components/Loading/Loading";
import spinner from "../../../assets/img/spinner.svg";

// hook
import useKeywordCategories from "../../../hooks/useKeywordCategories";

import "./KeywordCategories.css";

function KeywordCategories() {
  const [reorgLoading, setReorgLoading] = useState(false);
  const [reorgError, setReorgError] = useState(null);
  // input fields state
  const [newKeywords, setNewKeywords] = useState({
    // condition
    clinical_trials: "",
    support: "",
    preventative: "",
    treatment: "",
    diagnosis: "",
    symptoms: "",
    general_condition: "",
    // branded
    evaluation: "",
    payment: "",
    side_effects: "",
    usage: "",
    assistance: "",
    general_branded: "",
    // negative
    animals: "",
    explicit: "",
    other_global_condition_negatives: "",
  });

  // loading state for each category
  const [fieldLoading, setFieldLoading] = useState({
    // condition
    clinical_trials: false,
    support: false,
    preventative: false,
    treatment: false,
    diagnosis: false,
    symptoms: false,
    general_condition: false,
    // branded
    evaluation: false,
    payment: false,
    side_effects: false,
    usage: false,
    assistance: false,
    general_branded: false,
    // negative
    animals: false,
    explicit: false,
    other_global_condition_negatives: false,
  });

  // fetch keyword categories data from the hook
  const { keywordsForm, loading, dispatch } = useKeywordCategories();

  const handleUpdateKeywordField = (keyword, value) => {
    setNewKeywords((prevKeywords) => ({
      ...prevKeywords,
      [keyword]: value,
    }));
  };

  const handleAppendItem = async (list, item) => {
    if (!item) return;

    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    const lowerCaseItem = item.toLowerCase();
    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: [
        ...keywordsForm[list].keyword_set,
        { value: lowerCaseItem },
      ],
    };

    try {
      const { data } = await $axios.patch(
        `keywords_list/${updatedCategoryObj.uuid}/`,
        updatedCategoryObj
      );
      dispatch({ type: "UPDATE_CATEGORY", field: list, value: data });
    } catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert(
          "You have reached the maximum number of requests. Please try again later."
        );
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));

      // reset the input field associated with the list
      setNewKeywords((prev) => ({ ...prev, [list]: "" }));
    }
  };

  const handleRemoveItem = async (list, index) => {
    setFieldLoading((prev) => ({ ...prev, [list]: true }));

    // remove the item from the list
    const updatedCategoryObj = {
      ...keywordsForm[list],
      keyword_set: keywordsForm[list].keyword_set.filter((_, i) => i !== index),
    };

    try {
      const { data } = await $axios.patch(
        `keywords_list/${updatedCategoryObj.uuid}/`,
        updatedCategoryObj
      );
      dispatch({ type: "UPDATE_CATEGORY", field: list, value: data });
    } catch (error) {
      console.error(error);

      // check for 429 status code and display a message to the user
      if (error.response.status === 429) {
        alert(
          "You have reached the maximum number of requests. Please try again later."
        );
      }
    } finally {
      setFieldLoading((prev) => ({ ...prev, [list]: false }));
    }
  };

  const handleProjectReorganization = async () => {
    setReorgLoading(true);
    setReorgError(null);

    try {
      const res = await $axios.post(
        "/company/recategorize_and_process_keywords_view/"
      );
    } catch (error) {
      setReorgError("Failed to process request");
      console.error(error);
    } finally {
      setReorgLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}

      <div className="admin-page keyword-categories-page page-base">
        <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            <li>
              <Link to="/admin">Admin</Link>
              <FontAwesomeIcon
                className="ml-2 text-blueberry-500 dark:text-cherry-white"
                icon={faChevronRight}
                aria-hidden="true"
              />
            </li>

            <li className="font-bold text-watermelon-500">
              Keyword Categories
            </li>
          </ol>
        </nav>

        <h1>Keyword Categories</h1>

        <section className="page-section">
          <h2 className="section-header">Condition</h2>

          <div className="form-groups">
            <div className="form-group clinical-trials">
              <h3>Clinical Trials (Trials)</h3>

              <KeywordField
                placeholder={"Add Clinical Trials Keyword"}
                list={keywordsForm.clinical_trials.keyword_set}
                type="clinical_trials"
                value={newKeywords.clinical_trials}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.clinical_trials}
              />
            </div>
            
            <div className="form-group support">
              <h3>Support</h3>

              <KeywordField
                placeholder={"Add Support Keyword"}
                list={keywordsForm.support.keyword_set}
                type="support"
                value={newKeywords.support}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.support}
              />
            </div>
            
            <div className="form-group preventative">
              <h3>Preventative</h3>

              <KeywordField
                placeholder={"Add Preventative Keyword"}
                list={keywordsForm.preventative.keyword_set}
                type="preventative"
                value={newKeywords.preventative}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.preventative}
              />
            </div>
            
            <div className="form-group treatment">
              <h3>Treatment</h3>

              <KeywordField
                placeholder={"Add Treatment Keyword"}
                list={keywordsForm.treatment.keyword_set}
                type="treatment"
                value={newKeywords.treatment}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.treatment}
              />
            </div>

            <div className="form-group diagnosis">
              <h3>Diagnosis</h3>

              <KeywordField
                placeholder={"Add Diagnosis Keyword"}
                list={keywordsForm.diagnosis.keyword_set}
                type="diagnosis"
                value={newKeywords.diagnosis}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.diagnosis}
              />
            </div>

            <div className="form-group symptoms">
              <h3>Symptoms</h3>

              <KeywordField
                placeholder={"Add Symptoms Keyword"}
                list={keywordsForm.symptoms.keyword_set}
                type="symptoms"
                value={newKeywords.symptoms}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.symptoms}
              />
            </div>

            <div className="form-group general-condition">
              <h3>General Condition</h3>

              <KeywordField
                placeholder={"Add General Condition Keyword"}
                list={keywordsForm.general_condition.keyword_set}
                type="general_condition"
                value={newKeywords.general_condition}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.general_condition}
              />
            </div>
          </div>
        </section>

        <section className="page-section">
          <h2 className="section-header">Branded</h2>

          <div className="form-groups">
            <div className="form-group evaluation">
              <h3>Evaluation</h3>

              <KeywordField
                placeholder={"Add Evaluation Keyword"}
                list={keywordsForm.evaluation.keyword_set}
                type="evaluation"
                value={newKeywords.evaluation}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.evaluation}
              />
            </div>

            <div className="form-group payment">
              <h3>Payment</h3>

              <KeywordField
                placeholder={"Add Payment Keyword"}
                list={keywordsForm.payment.keyword_set}
                type="payment"
                value={newKeywords.payment}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.payment}
              />
            </div>

            <div className="form-group side-effects">
              <h3>Side Effects</h3>

              <KeywordField
                placeholder={"Add Side Effects Keyword"}
                list={keywordsForm.side_effects.keyword_set}
                type="side_effects"
                value={newKeywords.side_effects}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.side_effects}
              />
            </div>

            <div className="form-group usage">
              <h3>Usage</h3>

              <KeywordField
                placeholder={"Add Usage Keyword"}
                list={keywordsForm.usage.keyword_set}
                type="usage"
                value={newKeywords.usage}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.usage}
              />
            </div>

            <div className="form-group assistance">
              <h3>Assistance</h3>

              <KeywordField
                placeholder={"Add Assistance Keyword"}
                list={keywordsForm.assistance.keyword_set}
                type="assistance"
                value={newKeywords.assistance}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.assistance}
              />
            </div>

            <div className="form-group general-branded">
              <h3>General Branded</h3>

              <KeywordField
                placeholder={"Add General Branded Keyword"}
                list={keywordsForm.general_branded.keyword_set}
                type="general_branded"
                value={newKeywords.general_branded}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.general_branded}
              />
            </div>
          </div>
        </section>

        <section className="page-section">
          <h2 className="section-header">Negative</h2>

          <div className="form-groups">
            <div className="form-group animals">
              <h3>Animals</h3>

              <KeywordField
                placeholder={"Add Animals Keyword"}
                list={keywordsForm.animals.keyword_set}
                type="animals"
                value={newKeywords.animals}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.animals}
              />
            </div>

            <div className="form-group explicit">
              <h3>Explicit</h3>

              <KeywordField
                placeholder={"Add Explicit Keyword"}
                list={keywordsForm.explicit.keyword_set}
                type="explicit"
                value={newKeywords.explicit}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.explicit}
              />
            </div>

            <div className="form-group other-negatives">
              <h3>Other Negatives</h3>

              <KeywordField
                placeholder={"Add Other Negatives Keyword"}
                list={keywordsForm.other_global_condition_negatives.keyword_set}
                type="other_global_condition_negatives"
                value={newKeywords.other_global_condition_negatives}
                onChange={handleUpdateKeywordField}
                onAdd={handleAppendItem}
                onRemove={handleRemoveItem}
                disabled={fieldLoading.other_global_condition_negatives}
              />
            </div>
          </div>
        </section>

        <section className="page-section">
          <h2 className="section-header">Reorganize</h2>

          <div className="p-4 flex flex-col md:flex-row justify-between items-center gap-4">
            <p>Reorganize all priority projects with the latest keywords.</p>

            <div className="flex flex-col gap-2 w-full md:w-auto">
              <button
                className="btn w-full md:w-[400px]"
                onClick={handleProjectReorganization}
                disabled={reorgLoading}
                aria-label="Reorganize Projects"
                aria-busy={reorgLoading}
              >
                {reorgLoading ? (
                  <img src={spinner} alt="loading spinner" className="w-7 h-7" />
                ) : (
                  "Run"
                )}
              </button>
              <span
                className="text-red-500 italic text-center w-full max-w-[400px]"
                aria-live="assertive"
              >
                {reorgError}
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default KeywordCategories;
