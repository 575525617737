import { Navigate, Outlet } from "react-router-dom";
import useSessionStore from '../stores/session.js'

import useScrollToTop from "../hooks/useScrollToTop.js";

const ProtectedRoute = () => {
  useScrollToTop();
  const user = useSessionStore(state => state.user)

  // if user does not exist, redirect to login page
  // save the current location to redirect back to after login
  if (user && user.hasOwnProperty('uid')) {
    return <Outlet />
  } else {
    // save the current location to redirect back to after login
    sessionStorage.setItem('redirectPath', window.location.pathname)
    return <Navigate to="/" replace />
  }
}

export default ProtectedRoute;