// react
import { Link } from 'react-router-dom';
import { useState } from 'react';

// axios
import { $axios } from '../../services/axiosInstance';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

// styles
import './CompanyAdd.css';

const CompanyAddEdit = () => {
  const [companyForm, setCompany] = useState({
    name: '',
    ticker: '',
  });

  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
  
    $axios.post('/company/company/', companyForm)
      .then(() => {})
      .catch((err) => {
        // Accessing error message directly from 'error' key
        let errorMessage = err.response && err.response.data && err.response.data.error;
        if (errorMessage) {
          // Handle error from server response
          console.error("Error occurred:", errorMessage);
          setError(errorMessage);
        } else {
          console.error(err);
        }
      });
  }  

  return <>
    <section className="company-add-edit">
      <div className="page-header">
        <Link to="/companies" className="">
          <FontAwesomeIcon icon={faCircleArrowLeft} size="xl" color='#F814BF' />
        </Link>
        <h1>Submit Company</h1>
      </div>
    
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="companyName">Company Name</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={companyForm.name}
            onChange={(e) => setCompany({ ...companyForm, name: e.target.value})}
          />
        </div>

        <div className="form-group">
          <label htmlFor="companyTicker">Company Ticker</label>
          <input
            type="text"
            id="companyTicker"
            name="companyTicker"
            value={companyForm.ticker}
            onChange={(e) => setCompany({ ...companyForm, ticker: e.target.value})}
          />
        </div>

        <div className="form-group" style={{ marginBottom: '1rem', color: 'red', fontStyle: 'italic', fontSize: '0.8rem' }}>
            {error && <div className="error-message">{error}</div>}
        </div>

        <button type="submit" className="btn btn-primary">
          Add Company
        </button>
      </form>
    </section>
  </>;
}

export default CompanyAddEdit;
