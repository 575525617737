export const formatFloat = (value, fixed = 2) => {
  if (value === null || value === undefined || isNaN(value)) return "-";

  // Ensure the value is a number and fixed to the specified decimal places
  const fixedValue = parseFloat(value).toFixed(fixed);

  // Split the fixed value into integer and decimal parts
  const parts = fixedValue.split(".");

  // Format the integer part with commas
  parts[0] = Number(parts[0]).toLocaleString();

  // Reassemble the parts
  return parts.join(".");
}

// round float to 0 decimal places, whole number
export const roundFloat = (value) => {
  if (value === null || value === undefined || isNaN(value)) return "-";

  return Math.round(value).toLocaleString();
}

export const formatCurrency = (value, fixed = 2) => {
  if (value === null || value === undefined || isNaN(value)) return "-";

  // Ensure the value is a number and fixed to 2 decimal places
  const fixedValue = parseFloat(value).toFixed(fixed);

  // Split the fixed value into integer and decimal parts
  const parts = fixedValue.split(".");
  // Format the integer part with commas
  parts[0] = Number(parts[0]).toLocaleString();

  // Combine the integer and decimal parts
  return `$${parts.join(".")}`;
}

export const formatPercent = (value, fixed = 2) => {
  if (value === null || value === undefined || isNaN(value)) return "-";

  return parseFloat(value).toFixed(fixed).toLocaleString() + "%";
}

export const formatLargeNumber = (number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return 'N/A';
  }

  if (number >= 1000000) {
    return (Math.floor(number / 100000) / 10).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (number >= 1000) {
    return (Math.floor(number / 100) / 10).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return number.toString();
}

export const getSparklineOptions = (id, data, theme, color, dates) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // format trend data for sparkline
  const trendData = data.map((data, index) => {
    if (!dates) {
      const monthName = monthNames[index];
      return {
        y: data || 0,
        tooltip: `<strong>${monthName}</strong><br/>${data ? data.toLocaleString() : 0}`
      }
    }

    // format incoming date (yyyy-mm-dd) to month name and year (e.g. January 2021)
    const dateParts = dates[index].split('-');
    const monthName = monthNames[parseInt(dateParts[1]) - 1];
    const year = dateParts[0];
    
    return {
      y: data || 0,
      tooltip: `<strong>${monthName} ${year}</strong><br/>${data ? data.toLocaleString() : 0}`
    }
  });

  return {
    gui: {
      layouts: [{
        rows: [{
          cells: [{
            id: `cell-${id}-sparkline-chart`,
          }]
        }]
      }]
    },
    components: [{
      type: 'Highcharts',
      cell: `cell-${id}-sparkline-chart`,
      chartOptions: {
        chart: {
          styledMode: false,
          height: 75,
          width: 286,
        },
        series: [{
          label: null,
          data: trendData,
          type: 'area',
          color: color || '#fff',
          fillOpacity: 0.3,
          tooltip: {
            outside: true,
            valueDecimals: 2,
            shared: false,
            useHTML: true, // necessary if you're using HTML in your tooltip
            headerFormat: '', // disable the default header
            pointFormat: '{point.tooltip}', // use custom point tooltip
            style: {
              backgroundColor: `${color}`,
              color: color
            }
          },
          marker: {
            enabled: false
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 1
            }
          }
        }],
        plotOptions: {
          series: {
            // hex color add 0.2 opacity
            fillColor: color ? `${color}20` : `#ffffff20`,
          }
        },
        title: {
          text: ''
        },
        exporting: {
          enabled: false
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false
        },
        xAxis: {
          visible: false
        },
        yAxis: {
          visible: false
        },
      }
    }]
  }
}