import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import useCluster from "./hooks/useCluster";

import Loading from "../../components/Loading/Loading";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import Lightbox from "./Lightbox/Lightbox";

import "./Cluster.css";
import { formatCurrency, formatFloat, formatPercent } from "../../utils/tableUtils";

function Cluster() {
  const location = useLocation();
  const history = location.state?.history || [];
  const { profileId } = useParams();
  const { cluster, loading, error } = useCluster(profileId);
  const [isVisible, setIsVisible] = useState(false);

  const handleLightbox = () => {
    setIsVisible(!isVisible);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        { history && history.length > 0 && <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            {history.map((breadcrumb, index) => (
              <li key={index} className="flex items-center">
                <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>

                  <FontAwesomeIcon
                    className="ml-2 text-black dark:text-white"
                    icon={faChevronRight}
                    aria-hidden="true"
                  />

              </li>
            ))}
            <li className="font-bold text-watermelon-500">
              Cluster
            </li>
          </ol>
        </nav> }
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              { error?.message || "Something went wrong." }
            </p>
          </div>
        </section>
      </div>
    );
  }

  if(cluster == null || Object.keys(cluster).length === 0) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        { history && history.length > 0 && <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            {history.map((breadcrumb, index) => (
              <li key={index} className="flex items-center">
                <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>

                  <FontAwesomeIcon
                    className="ml-2 text-black dark:text-white"
                    icon={faChevronRight}
                    aria-hidden="true"
                  />

              </li>
            ))}
            <li className="font-bold text-watermelon-500">
              Cluster
            </li>
          </ol>
        </nav> }
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">No cluster data available for this profile.</p>
          </div>
        </section>
      </div>
    );
  }

  return <>
    <div className="cluster page-base space-y-4 md:space-y-8">
      { history && history.length > 0 && <nav aria-label="breadcrumbs">
          <ol className="flex gap-2 text-xs md:text-sm items-center">
            {history.map((breadcrumb, index) => (
              <li key={index} className="flex items-center">
                <Link to={breadcrumb.path} state={{ history: history.slice(0, index) }}>{breadcrumb.name}</Link>
                  <FontAwesomeIcon
                    className="ml-2 text-black dark:text-white"
                    icon={faChevronRight}
                    aria-hidden="true"
                  />
              </li>
            ))}
            <li className="font-bold text-watermelon-500">
              Cluster
            </li>
          </ol>
        </nav> 
      }

      <section className="page-section">
        <h1 className="section-header">{cluster.cluster_data.title}</h1>
        
        <div className="cluster-overview">
          <div className="bg-[#0561B7] bg-opacity-10 rounded border-[#0561B7] border border-opacity-50 p-6 text-xl">
            <p>{cluster.cluster_data.summary}</p>
          </div>

          <div className="row-1" style={{  }}>
            <img
              className="cluster-img"
              src={cluster.cluster_data.cluster_img}
              alt=""
              onClick={handleLightbox}
            />

            <div className="page-section w-full p-4 space-y-4 bg-[#0561B7] bg-opacity-10">
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[154px]">
                  Total Cluster AMSV:
                </h2>
                <p className="flex-1">
                  { formatFloat(cluster?.cluster_data?.total_search_volume, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[154px]">Cluster KW Count:</h2>
                <p className="flex-1">
                { formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[154px]">{`Share of Branded:`}</h2>
                <p className="flex-1">
                  { formatPercent(cluster?.cluster_data?.visualizations?.cluster_share[1].y, 2) }
                </p>
              </div>
            </div>

            <div className="page-section w-full p-4 space-y-4 bg-[#0561B7] bg-opacity-10">
              <div className="flex gap-4 items-baseline">
                <h2 className="font-bold min-w-[115px]">Top Keyword:</h2>
                <p className="flex-1">
                  { cluster?.cluster_data?.visualizations?.keywords?.length > 0 
                      ? cluster.cluster_data.visualizations.keywords[0].value 
                      : "N/A" 
                  }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[115px]">Avg. Difficulty:</h2>
                <p className="flex-1">
                  { formatPercent(cluster?.cluster_data?.avg_diff, 0) }
                </p>
              </div>
              <div className="flex gap-4 items-center">
                <h2 className="font-bold min-w-[115px]">Avg. CPC:</h2>
                <p className="flex-1">
                  { formatCurrency(cluster?.cluster_data?.avg_cpc) }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Data Visualizations</h2>

        <div className="pb-2">
          <ChartSlider>
            <SearchVolMonth
              data={cluster.cluster_data.total_search_volume_by_month}
            />
            <SearchVolBubble
              data={cluster.cluster_data.visualizations.search_volume_by_type}
            />
            <PaidSERP data={cluster.cluster_data.visualizations.paid_serp} />
            <ClusterSERPFeatures data={cluster.cluster_data.visualizations.serp_features} />
          </ChartSlider>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Intent</h2>

        <div className="cluster-summar p-6 space-y-6">
          <ol className="grid md:grid-cols-2 gap-x-4 md:gap-x-24 gap-y-4 md:gap-y-6 w-full">
            {cluster.cluster_data.search_motivations.map((reason, index) => (
              <li key={index}>
                <h3 className="font-bold text-lg">
                  { reason.title }
                </h3>
                <p className="">{reason.description}</p>
              </li>
            ))}
          </ol>
        </div>
      </section>

      <section className="page-section overflow-x-auto">
        <ClusterTable 
          data={cluster.cluster_data.visualizations.keywords} 
          profileId={profileId}
          history={history}
        />
      </section>
    </div>
    
    <Lightbox
      imageSrc={cluster.cluster_data.cluster_img}
      title={cluster.cluster_data.title}
      summary={cluster.cluster_data.summary}
      onClose={handleLightbox}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
    />
  </>;
}

export default Cluster;
