import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import './ProjectDifficultyIcon.css'

const ProjectDifficultyIcon = ({ acnu_difficulty }) => {
  const difficulty = acnu_difficulty || "unassessed"

  return <div className="difficulty-container">
    <div className="icon-bg">
      <FontAwesomeIcon title={`${difficulty}`} icon={faCircle} className={difficulty} />
    </div>
    <div id="difficulty-label" className="difficulty-label">
      <span className="sr-only">Project Difficulty:</span> { difficulty }
    </div>
  </div>
}

export default ProjectDifficultyIcon;