import { useState, useEffect, useReducer } from 'react';
import { $axios } from '../services/axiosInstance';

import { initialFormState, formReducer } from '../pages/Admin/KeywordCategories/formReducer';

const useKeywordCategories = () => {
  const [keywordsForm, dispatch] = useReducer(formReducer, initialFormState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeywordCategories = async () => {
      try {
        const { data } = await $axios.get('/keywords_list');
        dispatch({ type: 'SET_PROJECT_DATA', projectData: data.results || [] });
      } catch (error) {
        setError(error);
        // check for 429 status code and display a message to the user
        if (error.response?.status === 429 && error.response?.data?.detail) {
          alert(error.response.data.detail);
        } else {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchKeywordCategories();
  }, []);

  return { keywordsForm, loading, error, dispatch };
};

export default useKeywordCategories;