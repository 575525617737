const ExternalLink = ({ url, text }) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  return (
    <a href={url} target="_blank" rel="noreferrer" onClick={handleClick}>
      {text}
    </a>
  );
};

export default ExternalLink;