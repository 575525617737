import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

import { formatCurrency,  } from "../../../../utils/tableUtils.js";
import { generateGradientColors } from "../../../../utils/chartUtils.js";

import "./SearchSpendRec.css";

// data: project.spending_recs
function SearchSpendRec({ data }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null || data.length === 0) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if(data == null || data.length === 0) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Search Spend Rec data is not available.</p>
  }

  return (
    <div className="search-spend-rec">
      <div className="search-spend-content">
        <div className="search-spend-col col-1">
          <ChartDashboard config={options} id="search-spend-container" />
        </div>
      </div>
    </div>
  );
}

export default SearchSpendRec;

const chartOptions = (data, theme) => {
  const colors = theme === 'dark' 
  ? generateGradientColors('#020021', '#FCFCFC', data.length)
  : generateGradientColors('#0561B7', '#C4D6EB', data.length);

  return {
    dataPool: {
      connectors: [
        {
          id: 'micro-element',
          type: 'JSON',
          options: {
            firstRowAsNames: false,
            columnNames: ["Category", "Example Keywords", "Mthly. Spend Reco*"],
            data: data,
          },
        },
      ],
    },
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: 'dashboard-col-1',
                  responsive: {
                    small: {
                      width: '100%',
                    },
                    medium: {
                      width: '66.6%',
                    },
                    large: {
                      width: '66.6%',
                    },
                  }
                },
                {
                  id: 'dashboard-col-2',
                  responsive: {
                    small: {
                      width: '100%',
                    },
                    medium: {
                      width: '33.3%',
                    },
                    large: {
                      width: '33.3%',
                    },
                  }
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        cell: 'dashboard-col-1',
        connector: {
          id: 'micro-element',
        },
        type: 'DataGrid',
        className: 'search-spend-table',
        dataGridOptions: {
          editable: false,
          columns: {
            "Example Keywords": {
              cellFormatter: function () {
                // only allow a length of 50 characters
                return this.value.length > 75 ? this.value.substring(0, 50).trim() + '...' : this.value;
              }
            },
            "Mthly. Spend Reco*": {
              cellFormatter: function () {
                return formatCurrency(this.value, 0);
              }
            }
          }
        },
        sync: {
          highlight: true,
          visibility: true,
        },
      },
      {
        cell: 'dashboard-col-2',
        sync: {
          visibility: true,
          highlight: true,
          extremes: true,
        },
        connector: {
          id: 'micro-element',
        },
        type: 'Highcharts',
        columnAssignment: {
          'Category': 'x',
          "Mthly. Spend Reco*": 'y',
        },
        chartOptions: {
          colors: colors,
          chart: {
            styledMode: false,
            type: 'pie',
            height: 460
          },
          xAxis: {
            type: 'category',
          },
          yAxis: {
            title: {
              text: 'mcg',
            },
          },
          credits: {
            enabled: false,
          },
  
          title: {
            text: 'YRLY Spend Estimate',
          },

          tooltip: {
            stickOnContact: true,
            formatter: function () {
              const colorIndex = this.point.colorIndex || 0;
              const color = colors[colorIndex];

              return `<span style="color:${color}">●</span> <b>${this.point.name}</b>: ${this.point.percentage.toFixed(2)}%`;
            },
          },
          plotOptions: {
            pie: {
              innerSize: '60%',
              showInLegend: true,
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: [
              {
                distance: -60,
                enabled: true,
                format: ''
              }
            ]
          }
          },
        }
      },
    ],
  }
};
