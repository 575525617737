import { useState } from 'react';
import { Fragment } from 'react';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Highcharts from 'highcharts/highstock';

import addHighchartsMore from 'highcharts/highcharts-more';
// import ChartDashboard from '../../../../components/ChartDashboard/ChartDashboard.jsx';

// styles
import "../Tables.css"

// theme
import { useTheme } from '../../../../utils/ThemeManager.jsx'
import { brandSort, conditionSort, generateGradientColors } from '../../../../utils/chartUtils.js';

// table utils
import { 
  roundFloat,
  formatPercent, 
  formatCurrency,
  getSparklineOptions
} from '../../../../utils/tableUtils.js';

addHighchartsMore(Highcharts);

// HeaderRow 
// the header row of the table
export const HeaderRow = () => {
  return <>
    <div className="grid-item header">Category</div>
    {/* <div className="grid-item header center">Difficulty*</div> */}
    {/* <div className="grid-item header center">Opportunity*</div> */}
    <div className="grid-item header center">Count</div>
    <div className="grid-item header center">Annual Volume</div>
    <div className="grid-item header center">Estimated CTR*</div>
    <div className="grid-item header center">Estimated Visits</div>
    <div className="grid-item header center">Average CPC*</div>
    <div className="grid-item header center">Estimated Total Cost</div>
    {/* <div className="grid-item header center">Estimated Monthly Cost</div> */}
    {/* <div className="grid-item header center">Spark Chart</div> */}
  </>
}

// TableRow 
// is for each keyword category
// needs index for colored dot
export const TableRow = ({row, category_type, value, sparklineOptions, color, showBranded, showCondition}) => {
  // remove everything after the Uncategorized keyword
  const formattedRow = row.split(' - ')[0];

  if(category_type === 'Branded' && !showBranded) {
    return null;
  }

  if(category_type === 'Condition' && !showCondition) {
    return null;
  }

  return <Fragment key={`keyword-universe-${row}`}>
  <div className={`category-${formattedRow.replace(" ", "-")} grid-item data whitespace-nowrap space-x-2 gap-2`}>
    <svg width="12" height="12" fill={color}>
      <rect width="12" height="12" rx="6" ry="6" />
    </svg>
    {formattedRow}
  </div>

  <div className={`count grid-item data right`}>
    { roundFloat(value.keyword_count) }
  </div>

  <div className={`annual-volume grid-item data right`}>
    { roundFloat(value.search_volume_avg_annual_total) }
  </div>

  <div className={`estimated-ctr grid-item data right`}>
    { formatPercent(value.click_through_rate, 1) }
  </div>

  <div className="estimated-visits grid-item data right">
    { roundFloat(value.estimated_visits) }  
  </div>

  <div className="avg-cpc grid-item data right">
    { formatCurrency(value.cpc_final_avg) }  
  </div>

  <div className="estimated-cost-total grid-item data right">
    { formatCurrency(value.estimated_total_cost) }  
  </div>

  {/* <div className="estimated-cost-monthly grid-item data right">
    { formatCurrency(value.estimated_monthly_cost) }  
  </div> */}

  {/* <div className={`spark grid-item data p-0`}>
    <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${row}`} />
  </div> */}
</Fragment>
}

// Subtotal Row 
// is especially for the "Branded Subtotal" and "Condition Subtotal" rows
const SubtotalRow = ({ row, value, sparklineOptions, index, setBranded, showBranded, setCondition, showCondition }) => {
  const toggleSection = (e) => {
    const section = e.currentTarget.value;

    if(section === 'Brand') {
      setBranded(!showBranded);
    } else if(section === 'Condition') {
      setCondition(!showCondition);
    }
  }

  return <Fragment key={`keyword-category-${row}`}>
    <div className={`category-${row.replace(' ', '-')} grid-item data subtotal whitespace-nowrap`}>
      <button className="flex items-center gap-2" onClick={toggleSection} value={row.split(' ')[0]}>
        <FontAwesomeIcon icon={faChevronRight} size="xs" className={`transform ${row.split(' ')[0] === 'Brand' ? showBranded ? 'rotate-90' : '' : showCondition ? 'rotate-90' : ''}`} />
        {row}
      </button>
    </div>

    <div className="count grid-item data subtotal right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="annual-volume grid-item data subtotal right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className="estimated-ctr grid-item data subtotal right">
      { formatPercent(value.ctr_final_avg, 1) }
    </div>

    <div className="estimated-visits grid-item data subtotal right">
      { roundFloat(value.total_estimated_visits) }
    </div>

    <div className="avg-cpc grid-item data subtotal right">
      { formatCurrency(value.cpc_final_avg) }
    </div>

    <div className="estimated-cost-total grid-item data subtotal right">
      { formatCurrency(value.estimated_final_annual_cost) }
    </div>

    {/* <div className="estimated-cost-monthly grid-item data subtotal right">
      { formatCurrency(value.estimated_final_monthly_cost) }
    </div> */}

    {/* <div className="spark grid-item data subtotal p-0">
      <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${index}`} />
    </div> */}
  </Fragment>
}

// Total Row 
// is for the "Grand Total" row
const TotalRow = ({ row, value, sparklineOptions, index }) => {
  return <Fragment key={`keyword-category-${row}`}>
    <div className="category-grand-total grid-item data total whitespace-nowrap">
      {row}
    </div>

    <div className="category-grand-total count grid-item data total right">
      { roundFloat(value.total_keyword_count) }
    </div>

    <div className="category-grand-total annual-volume grid-item data total right">
      { roundFloat(value.search_volume_avg_annual_total) }
    </div>

    <div className="category-grand-total estimated-ctr grid-item data total right">
      { formatPercent(value.ctr_final_avg, 1) }
    </div>

    <div className="category-grand-total estimated-visits grid-item data total right">
      { roundFloat(value.total_estimated_visits) }
    </div>

    <div className="category-grand-total avg-cpc grid-item data total right">
      { formatCurrency(value.cpc_final_avg) }
    </div>

    <div className="category-grand-total estimated-cost-total grid-item data total right">
      { formatCurrency(value.estimated_final_annual_cost) }
    </div>

    {/* <div className="category-grand-total spark grid-item data total p-0">
      <ChartDashboard config={sparklineOptions} id={`sparkline-chart-${index}`} />
    </div> */}
  </Fragment>
}

// TableRows 
// the main component that maps over the data object and returns a TableRow or SubtotalRow component
// data: project.keyword_universe
const TableRows = ({ data, showBranded, showCondition, }) => {
  const { theme } = useTheme();
  const keys = Object.keys(data)

  const colors = theme === 'dark' 
    ? generateGradientColors('#FCFCFC', '#020021', keys.length)
    : generateGradientColors('#C4D6EB', '#0561B7', keys.length);

  // map over the keys and return a table row for each
  return keys.map((key, index) => {
    const sparklineOptions = getSparklineOptions(key, data[key].search_volume_monthly_totals, theme, '#0561B7');

    // otherwise, render the TableRow component
    return <TableRow
      row={key}
      category_type={data[key].category_type}
      value={data[key]}
      sparklineOptions={sparklineOptions}
      key={key}
      color={colors[index]}
      showBranded={showBranded}
      showCondition={showCondition}
    />
  })
}

// data: project.keyword_universe
const UniverseTable = ({ data }) => {
  const [showBranded, setShowBranded] = useState(false);
  const [showCondition, setShowCondition] = useState(false);

  // sort
  const conditionSubtotal = data['Condition Subtotal'];
  const conditionObjects = Object.entries(data)
    .filter(([key, value]) => value.category_type === 'Condition')
    .sort(([keyA], [keyB]) => conditionSort[keyA] - conditionSort[keyB])  // Sort the entries based on conditionSort order
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {})


  const brandSubtotal = data['Brand Subtotal'];
  const brandedObjects = Object.entries(data)
    .filter(([key, value]) => value.category_type === 'Branded')
    .sort(([keyA], [keyB]) => brandSort[keyA] - brandSort[keyB])  // Sort the entries based on brandSort order
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  const grandTotal = data['Grand Total'];

  if(data === null || Object.keys(data).length === 0 || conditionSubtotal == null || brandSubtotal == null || grandTotal == null) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">
      Keyword Universe table data is not available.
    </p>
  }

  return <>
    <div className="grid-container universe-category" role="table">
      <HeaderRow />

      {/* condition subtotal */}
      <SubtotalRow
        row="Condition Subtotal"
        value={conditionSubtotal}
        index={0}
        sparklineOptions={getSparklineOptions('Condition Subtotal', conditionSubtotal.search_volume_monthly_totals)}
        setCondition={setShowCondition}
        showCondition={showCondition}
      />

      {/* condition objects */}
      <TableRows data={conditionObjects} showCondition={showCondition} />

      {/* brand subtotal */}
      <SubtotalRow
        row="Brand Subtotal"
        value={brandSubtotal}
        index={6}
        sparklineOptions={getSparklineOptions('Brand Subtotal', brandSubtotal.search_volume_monthly_totals)}
        setBranded={setShowBranded}
        showBranded={showBranded}
      />

      {/* brand objects */}
      <TableRows data={brandedObjects} showBranded={showBranded} />

      {/* grand total */}
      <TotalRow
        row="Grand Total"
        value={grandTotal}
        index={10}
        sparklineOptions={getSparklineOptions('Grand Total', grandTotal.search_volume_monthly_totals)}
      />
    </div>
    <div className='text-xs col-span-full flex gap-x-0.5 items-center'>
      <div className='text-xl translate-y-[5px]'>*</div> &mdash; Subtotal values listed for these columns are the sums across given categories; grand total values listed are final averages across all categories
    </div>
  </>
}

export default UniverseTable;