// react
import { useNavigate, Link } from "react-router-dom";
import { useEffect, useReducer, useState, useCallback } from "react";

// hooks
import useCompanies from "../Companies/hooks/useCompanies";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";

// components
import Loading from "../../components/Loading/Loading";
import KeywordField from "./KeywordField";
import InputField from "./InputField";

// axios
import { $axios } from "../../services/axiosInstance";

import "./ProjectAdd.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      return { ...state, [action.field]: action.value };
    case "UPDATE_LIST":
      return {
        ...state,
        [action.field]: [...state[action.field], action.value],
      };
    case "REMOVE_FROM_LIST":
      return {
        ...state,
        [action.field]: state[action.field].filter(
          (item, i) => i !== action.index
        ),
      };
  }
};



const ProjectAdd = () => {
  const { companies, loading, error } = useCompanies();
  const navigate = useNavigate();

  const [newBranded, setNewBranded] = useState("");
  const [newUnbranded, setNewUnbranded] = useState("");
  const [newNegative, setNewNegative] = useState("");
  const [newCompURL, setNewCompURL] = useState("");
  const [formError, setFormError] = useState("");

  const initialFormState = {
    parent_company: "",
    name: "",
    url: "",
    branded_words: [],
    unbranded_words: [],
    negative_words: [],
    competitor_urls: [],
  };

  const [projectForm, dispatch] = useReducer(formReducer, initialFormState);

  // methods
  const handleInputChange = useCallback((e) => {
    dispatch({
      type: "UPDATE_FIELD",
      field: e.target.name,
      value: e.target.value,
    });
  }, []);

  const handleAppendItem = useCallback(
    (list, item) => {
      dispatch({ type: "UPDATE_LIST", field: list, value: item });

      switch (list) {
        case "branded_words":
          setNewBranded("");
          break;
        case "unbranded_words":
          setNewUnbranded("");
          break;
        case "negative_words":
          setNewNegative("");
          break;
        case "competitor_urls":
          setNewCompURL("");
          break;
      }
    },
    [projectForm]
  );

  const handleRemoveItem = useCallback(
    (list, index) => {
      dispatch({ type: "REMOVE_FROM_LIST", field: list, index: index });
    },
    [projectForm]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError("");

    try {
      await $axios.post("/company/project/", projectForm);

      navigate(`/companies`);
    } catch (err) {
      console.error(err);
      setFormError("Failed to add project");
    }
  };

  useEffect(() => {
    if (companies.length > 0) {
      dispatch({
        type: "UPDATE_FIELD",
        field: "parent_company",
        value: companies[0].uuid,
      });
    }
  }, [companies]);

  if (loading) return <Loading />;
  if (error) return <p>Error fetching companies</p>;

  return (
    <>
      <div className="project-add-edit page-base">
        <div className="page-header">
          <Link to={`/companies`} className="">
            <FontAwesomeIcon
              icon={faCircleArrowLeft}
              size="xl"
              color="#F814BF"
            />
          </Link>
          <h1 style={{ alignSelf: "flex-start" }}>Submit Project</h1>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          {/* Company Name */}
          <div className="form-group">
            <label htmlFor="parent_company">Company Name</label>
            <select
              name="parent_company"
              id="parent_company"
              value={projectForm.parent_company}
              onChange={handleInputChange}
            >
              {companies &&
                companies.map((company) => (
                  <option key={company.uuid} value={company.uuid}>
                    {company.name}
                  </option>
                ))}
            </select>
          </div>

          {/* Project Name */}
          <InputField
            id="projectName"
            name="name"
            label="Project Name"
            value={projectForm.name}
            onChange={handleInputChange}
          />

          {/* Project URL */}
          <InputField
            id="projectUrl"
            name="url"
            label="Project URL"
            value={projectForm.url}
            onChange={handleInputChange}
          />

          {/* Branded Keywords */}
          <KeywordField
            label="Top 50 Branded Keywords"
            placeholder={"Add branded keyword"}
            list={projectForm.branded_words}
            type="branded_words"
            value={newBranded}
            onChange={setNewBranded}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
          />

          {/* Unbranded Keywords */}
          <KeywordField
            label="Top 50 Unbranded Keywords"
            placeholder={"Add unbranded keyword"}
            list={projectForm.unbranded_words}
            type="unbranded_words"
            value={newUnbranded}
            onChange={setNewUnbranded}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
          />

          {/* Negative Keywords */}
          <KeywordField
            label="Top 50 Negative Keywords"
            placeholder={"Add negative keyword"}
            list={projectForm.negative_words}
            type="negative_words"
            value={newNegative}
            onChange={setNewNegative}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
          />

          {/* Competitive URLs */}
          <KeywordField
            label="Competitor URLs (limit 3)"
            placeholder={"Add competitor url"}
            list={projectForm.competitor_urls}
            type="competitor_urls"
            value={newCompURL}
            onChange={setNewCompURL}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
            disabled={projectForm.competitor_urls.length >= 3}
          />

          {/* API Feedback */}
          {formError && (
            <div className="form-error" role="alert">
              {formError}
            </div>
          )}

          {/* Submit button */}
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default ProjectAdd;
