// react
import { useEffect } from "react";
import { useReducer, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// global components
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs";
import ToolTipLabel from "../../components/ToolTipLabel/ToolTipLabel";

// local components
import KeywordField from "../../components/KeywordField/KeywordField";
import InputField from "./InputField";

import formReducer from "./formReducer";

// axios
import { $axios } from "../../services/axiosInstance";

import "./ProjectEdit.css";

const ProjectEdit = () => {
  const navigate = useNavigate();

  const [project, setProject] = useState(useLocation().state?.project || {});
  // const [newBranded, setNewBranded] = useState("");
  // const [newUnbranded, setNewUnbranded] = useState("");
  const [newNegative, setNewNegative] = useState("");
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(true);

  const initialFormState = {
    parent_company: project.parent_company || "",
    name: project.name || "",
    url: project.url || "",
    negative_words: project.negative_words || [],
    // semrush_keyword_count: project.semrush_keyword_count || 50,
    start_percentage: project.start_percentage || 0,
    outcome_percentage: project.outcome_percentage || 0,
    ok_to_use_percentage: project.ok_to_use_percentage || 0,
    purchase_percentage: project.purchase_percentage || 0,
    monthly_price: project.monthly_price || 0,
    monthly_cost: project.monthly_cost || 0,
  };

  const [projectForm, dispatch] = useReducer(formReducer, initialFormState);

  // methods
  const handleInputChange = useCallback((e) => {
    dispatch({ type: 'UPDATE_FIELD', field: e.target.name, value: e.target.value });
  }, []);

  const handleUpdateNegativeKeywordField = (_, value) => {
    setNewNegative(value);
  };

  const handleAppendItem = useCallback((list, item) => {
    if (!item) {
      return;
    }

    dispatch({ type: 'UPDATE_LIST', field: list, value: item });

    switch (list) {
      case "negative_words":
        setNewNegative("");
        break;
    }
  }, [projectForm]);

  const handleRemoveItem = useCallback((list, index) => {
    dispatch({ type: 'REMOVE_FROM_LIST', field: list, index: index });
  }, [projectForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedProject = {
      ...project,
      url: projectForm.url,
      negative_words: projectForm.negative_words,
      // semrush_keyword_count: projectForm.semrush_keyword_count,
      start_percentage: projectForm.start_percentage,
      outcome_percentage: projectForm.outcome_percentage,
      ok_to_use_percentage: projectForm.ok_to_use_percentage,
      purchase_percentage: projectForm.purchase_percentage,
      monthly_price: projectForm.monthly_price,
      monthly_cost: projectForm.monthly_cost,
    };
    
    try {
      const { data } = await $axios.put(`/company/project/${project.uuid}/`, updatedProject);
      if(data.last_run === null) {
        navigate(`/companies`)
      } else {
        navigate(`/project/${project.uuid}`, { state: { project: data }});
      }
    } catch (error) {
      setFormError(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const { data } = await $axios.get(`/company/project/${project.uuid}/`);
        setProject(data);
        dispatch({ type: 'SET_PROJECT_DATA', projectData: data });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (project.uuid) {
      fetchProject();
    } else {
      setLoading(false);
    }
  }, [project.uuid]);

  return <>
    <div className={`project-add-edit page-base ${ loading ? 'animate-pulse' : '' }`}>
      <DynamicCrumbs 
        current={`Edit ${ project.name }`} 
      />

      <div className="page-header">
        
      </div>

      <form className="page-section form" onSubmit={handleSubmit}>
        <h1 className="section-header">Edit Project</h1>
        <div className="form-group-container">
        {/* Company Name */}
        <InputField
          id="companyName"
          name="company_name"
          label="Company Name"
          value={projectForm.parent_company_name}
          onChange={handleInputChange}
          disabled={true}
        />

        {/* Project Name */}
        <InputField
          id="projectName"
          name="name"
          label="Project Name"
          value={projectForm.name}
          onChange={handleInputChange}
          disabled={true}
        />

        {/* Project URL */}
        <InputField
          id="projectUrl"
          className="md:col-span-full"
          name="url"
          label="Project URL"
          value={projectForm.url}
          onChange={handleInputChange}
        />

        {/* Start Percentage */}
        <div className="form-group">
          <ToolTipLabel
            labelText="Start"
            htmlFor="start_percentage"
            toolTipText={"Controls the percentage of keywords to start with."}
            elValue={projectForm.start_percentage}
          />
          <input
            type="range"
            name="start_percentage"
            id="start_percentage"
            min="0"
            max="100"
            step="0.01"
            value={projectForm.start_percentage}
            onChange={handleInputChange}
          />
        </div>

        {/* Outcome Percentage */}
        <div className="form-group">
          <ToolTipLabel
            labelText="Outcome"
            htmlFor="outcome_percentage"
            toolTipText={"Controls the percentage of keywords to end with."}
            elValue={projectForm.outcome_percentage}
          />
          <input
            type="range"
            name="outcome_percentage"
            id="outcome_percentage"
            min="0"
            max="100"
            step="0.01"
            value={projectForm.outcome_percentage}
            onChange={handleInputChange}
          />
        </div>

        {/* OK to use percenage */}
        <div className="form-group">
          <ToolTipLabel
            labelText="OK to Use"
            htmlFor="ok_to_use_percentage"
            toolTipText={"Controls the percentage of keywords to use."}
            elValue={projectForm.ok_to_use_percentage}
          />
          <input
            type="range"
            name="ok_to_use_percentage"
            id="ok_to_use_percentage"
            min="0"
            max="100"
            step="0.01"
            value={projectForm.ok_to_use_percentage}
            onChange={handleInputChange}
          />
        </div>

        {/* Purchase Percentage */}
        <div className="form-group">
          <ToolTipLabel
            labelText="Purchase"
            htmlFor="purchase_percentage"
            toolTipText={"Controls the percentage of keywords to purchase."}
            elValue={projectForm.purchase_percentage}
          />
          <input
            type="range"
            name="purchase_percentage"
            id="purchase_percentage"
            min="0"
            max="100"
            step="0.01"
            value={projectForm.purchase_percentage}
            onChange={handleInputChange}
          />
        </div>

        {/* Monthly Price */}
        <div className="form-group">
          <ToolTipLabel
            labelText="Monthly Price"
            htmlFor="monthly_price"
            toolTipText={"Controls the monthly price range."}
            elValue={projectForm.monthly_price}
          />
          <input
            type="range"
            name="monthly_price"
            id="monthly_price"
            min="0"
            max="1000"
            step="0.01"
            value={projectForm.monthly_price}
            onChange={handleInputChange}
          />
        </div>

        {/* Monthly Cost */}
        <div className="form-group">
          <ToolTipLabel
            labelText="Monthly Cost"
            htmlFor="monthly_cost"
            toolTipText={"Controls the monthly cost range."}
            elValue={projectForm.monthly_cost}
          />
          <input
            type="range"
            name="monthly_cost"
            id="monthly_cost"
            min="0"
            max="1000"
            step="0.01"
            value={projectForm.monthly_cost}
            onChange={handleInputChange}
          />
        </div>
        

        {/* Negative Keywords */}
        <div className="md:col-span-full">
          <KeywordField
            className={"full-width"}
            label="Top Negative Keywords"
            placeholder={"Add negative keyword"}
            list={projectForm.negative_words}
            type="negative_words"
            value={newNegative}
            onChange={handleUpdateNegativeKeywordField}
            onAdd={handleAppendItem}
            onRemove={handleRemoveItem}
          />
        </div>


        {/* API Feedback */}
        { formError && (
          <div className="form-error" role="alert">
            {formError} 
          </div>
        )}

        {/* Submit button */}
        <button type="submit" className="btn">
          Submit
        </button>
        </div>
      </form>
    </div>
  </>;
};

export default ProjectEdit;