import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HC_more from 'highcharts/highcharts-more';
import HighchartsSankey from 'highcharts/modules/sankey';
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "../../utils/ThemeManager.jsx";

import "./ConsumerSearchChart.css";

HC_more(Highcharts);
HighchartsSankey(Highcharts);
highchartsAccessibility(Highcharts);

// function for getting chart options
const getChartOptions = (data, theme) => {
  // convert data to an array of objects
  data = Object.values(data);

  return {
      colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
      chart: {
        className: `highcharts-${theme}`,
        styledMode: false,
      },
      title: {
        text: null,
      },
      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
        }
      },
      series: [{
        keys: ['from', 'to', 'weight'],
        data: data,
        type: 'sankey',
        name: 'Search activity'
      }]
    };
};

const ConsumerSearchChart = ({ data }) => {

  const { theme } = useTheme();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartOptions(getChartOptions(data, theme));
  }, [data, theme]);

  // check that data is not empty
  if (!data || Object.keys(data).length === 0) {
    return <div className="h-[450px] grid place-items-center font-bold md:text-xl">
      <p>No consumer search data available.</p>
    </div>;
  }

  return <HighchartsReact 
    containerProps={{ id: "consumer-search-chart", 'data-testid': "consumer-search-chart" }} 
    highcharts={Highcharts} 
    options={chartOptions} 
  />;
};

export default ConsumerSearchChart;