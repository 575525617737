// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleExclamation,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

import './Warnings.css';

function Warnings({ project }) {
  return <div className="warnings-container">
  <div className="info-box boxed">
    <FontAwesomeIcon
      className="hidden md:block md:h-6 md:w-6 lg:h-8 lg:w-8"
      icon={faTriangleExclamation}
    />
    <div className="content">
      <h3>Boxed Warnings</h3>

      {project.acnu_features &&
        (Array.isArray(project.acnu_features) 
          ? project.acnu_features.length > 0 && project.acnu_features[0].v1 && project.acnu_features[0].v1.boxed_warnings_count 
            ? <p data-testid="boxed-warnings">{project.acnu_features[0].v1.boxed_warnings_count}</p>
            : <p data-testid="boxed-warnings">0</p>
          : project.acnu_features.v1 && project.acnu_features.v1.boxed_warnings_count 
            ? <p data-testid="boxed-warnings">{project.acnu_features.v1.boxed_warnings_count}</p>
            : <p data-testid="boxed-warnings">N/A</p>
        )}
    </div>
  </div>

  <div className="info-box total">
    <FontAwesomeIcon
      className="hidden md:block md:h-6 md:w-6 lg:h-8 lg:w-8"
      icon={faCircleExclamation}
    />
    <div className="content">
      <h3>Total Warnings</h3>

      {project.acnu_features &&
        (Array.isArray(project.acnu_features) 
          ? project.acnu_features.length > 0 && project.acnu_features[0].v1 && project.acnu_features[0].v1.adverse_reactions_count 
            ? <p data-testid="total-warnings">{project.acnu_features[0].v1.adverse_reactions_count}</p>
            : <p data-testid="total-warnings">0</p>
          : project.acnu_features.v1 && project.acnu_features.v1.adverse_reactions_count 
            ? <p data-testid="total-warnings">{project.acnu_features.v1.adverse_reactions_count}</p>
            : <p data-testid="total-warnings">N/A</p>
        )}
    </div>
  </div>

  <div className="info-box controlled col-span-2 md:col-span-1">
    <FontAwesomeIcon
      className="hidden md:block md:h-6 md:w-6 lg:h-8 lg:w-8"
      icon={faBan}
      size="2xl"
    />
    <div className="content">
      <h3>Controlled Substance</h3>

      {project.acnu_features &&
        (Array.isArray(project.acnu_features) 
          ? project.acnu_features.length > 0 && project.acnu_features[0].v1 && project.acnu_features[0].v1.controlled_substance 
              ? <p data-testid="controlled-substance">{ project.acnu_features[0].v1.controlled_substance.toString() }</p>
              : <p data-testid="controlled-substance">0</p>
          : project.acnu_features.v1 && project.acnu_features.v1.controlled_substance 
              ? <p data-testid="controlled-substance">{ project.acnu_features.v1.controlled_substance.toString() }</p>
              : <p data-testid="controlled-substance">N/A</p>
          )}
    </div>
  </div>
</div>
}

export default Warnings;