import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a context for the theme
export const ThemeContext = createContext();

// Create a provider for the theme context
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    // Check the user's OS theme preference
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const osPreference = prefersDarkMode ? 'dark' : 'light';

    // Load theme from localStorage, if none, then fall back to the user's OS preference
    const localTheme = window.localStorage.getItem('theme');
    if (localTheme) {
      // If there's a theme saved in localStorage, use it
      setTheme(localTheme);
    } else {
      // Otherwise, use the user's OS preference
      setTheme(osPreference);
    }
  }, []);

  useEffect(() => {
    if (theme !== null) {
      window.localStorage.setItem('theme', theme);
      document.documentElement.setAttribute("data-theme", theme);

      // how?
      if(theme === 'dark') {
        document.documentElement.classList.add(theme)
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};