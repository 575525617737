import { useState, useEffect } from 'react';
import { $axios } from '../services/axiosInstance';

const useCompanyPolygon = (ticker) => {
  const [polygonData, setPolygonData] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const { data } = await $axios.get(`/fetch_polygon_company_data/${ticker}/`);
        
        setPolygonData(data);

      } catch (err) {
        console.error('useCompanyPolygon:', err);
      }
    };

    if(!ticker) {
      return;
    }

    fetchCompany();
  }, [ticker]);

  return [polygonData, setPolygonData];
};

export default useCompanyPolygon;