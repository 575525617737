import { useState, useEffect } from "react";
import { $axios } from "../../../../services/axiosInstance";

const useCondition = (conditionId) => {
  const [condition, setCondition] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCondition = async () => {
      try {
        // /company/condition_analysis/5ff8ba93-bc16-444a-8eb2-515801504b12/
        const { data } = await $axios.get(`/company/condition_analysis/${conditionId}/`);
        setCondition(data);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCondition();
  }, []);

  return { condition, loading, error };
}

export default useCondition;