import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle, 
  faCircleCheck, 
  faCircleHalfStroke,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import './ProjectStatusIcon.css';

const ProjectStatusIcon = ({ status }) => {
  switch(status) {
    case 'In Progress':
    case 'Queued':
      return <FontAwesomeIcon icon={faCircleHalfStroke} className="text-[#FF7347]" title="In Progress" />;
    case 'Success':
      return <FontAwesomeIcon icon={faCircleCheck} className="text-[#2DB300]" title="Success" />;
    case 'Failure':
      return <FontAwesomeIcon icon={faCircleXmark} className="text-[#B2294B]" title="Failure" />;
    default:
      return <FontAwesomeIcon icon={faQuestionCircle} className="text-[#0561B7]" title="Unknown" />;
  }
};

export default ProjectStatusIcon;