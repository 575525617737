// react
import { Link } from 'react-router-dom';

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Breadcrumbs = ({ company, project, currentPage }) => {

  const generateCrumbs = () => {
    const crumbs = [{ link: '/companies', text: 'Companies' }];

    // this means we're on a project page or project-related page
    if (project && project.uuid) {
      // company link
      crumbs.push({ link: `/company/${project.parent_company}`, text: project.parent_company_name || project.parent_company });
      // project link
      crumbs.push({ link: `/project/${project.uuid}`, text: project.name || project.uuid});
    }

    // this means we're on a company page
    if (!project && company && company.uuid) {
      crumbs.push({ link: `/company/${company.uuid}`, text: company.name || company.uuid });
    }

    // add a current page (for display only)
    // todo: possibly make this an array of pages as navigation gets more complex
    if (currentPage) {
      crumbs.push({ text: currentPage });
    }

    return crumbs;
  };

  const crumbs = generateCrumbs();

  const renderIcon = () => <FontAwesomeIcon className='mr-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />;


  // if project has no project.uuid, or company has no company.uuid, return null
  if (!project?.uuid && !company?.uuid) return null;

  return (
    <nav className="mb-4" aria-label="breadcrumbs">
      <ol className="flex gap-2 text-xs md:text-sm items-center">
        {crumbs.map((crumb, index) => (
          <li key={`${crumb.text}-${index}`} className={index === crumbs.length - 1 ? "font-bold text-watermelon-500 pointer-events-none" : ""}>
            {index > 0 && renderIcon()}
            {crumb.link 
              ? <Link to={crumb.link} state={{ company, project }}>{crumb.text}</Link> 
              : crumb.text}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;