import { useCallback, useReducer, useRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import ToolTipLabel from "../../../components/ToolTipLabel/ToolTipLabel";

import spinner from '../../../assets/img/spinner.svg';

import './ChartSettings.css';

const defaultProjectData = {
  start_percentage: 50,
  outcome_percentage: 50,
  ok_to_use_percentage: 50,
  purchase_percentage: 50,
  monthly_price: 500,
  monthly_cost: 500,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECT_DATA':
      return {
        ...state,
        ...action.projectData,
        negative_words: Array.isArray(action.projectData.negative_words) ? action.projectData.negative_words : [],
        start_percentage: action.projectData.start_percentage || 0,
        outcome_percentage: action.projectData.outcome_percentage || 0,
        ok_to_use_percentage: action.projectData.ok_to_use_percentage || 0,
        purchase_percentage: action.projectData.purchase_percentage || 0,
        monthly_price: action.projectData.monthly_price || 0,
        monthly_cost: action.projectData.monthly_cost || 0,

      };
    case 'UPDATE_FIELD':
      return { ...state, [action.field]: action.value };
    case 'RESET_DEFAULT':
      return defaultProjectData;
    default:
      return state;
  }
};

function ChartSettings({ project }) {
  const detailsRef = useRef(null);
  const initialFormState = {
    start_percentage: project.start_percentage || 0,
    outcome_percentage: project.outcome_percentage || 0,
    ok_to_use_percentage: project.ok_to_use_percentage || 0,
    purchase_percentage: project.purchase_percentage || 0,
    monthly_price: project.monthly_price || 0,
    monthly_cost: project.monthly_cost || 0,
  };

  const [loading, setLoading] = useState(false);

  const [projectForm, dispatch] = useReducer(formReducer, initialFormState);

  const handleInputChange = useCallback((e) => {
    dispatch({ type: 'UPDATE_FIELD', field: e.target.name, value: e.target.value });
  }, []);

  const handleReset = () => {
    dispatch({ type: 'RESET_DEFAULT' });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Simulate a network request
    setTimeout(() => {
      if (detailsRef.current) {
        detailsRef.current.removeAttribute('open');
      }
      setLoading(false);
    }, 2000);
  }

  return (
    <details id="details" className="chart-settings" ref={detailsRef}>
      <summary role="button">
        <FontAwesomeIcon icon={faGear} />
        <div id="cover"></div>
      </summary>

      <form className="dropdown-menu flex flex-col gap-8" onSubmit={handleSubmit}>
        <h2 className="text-center">Adjust Forecast Model</h2>
        
        <div className="flex-1 flex flex-col gap-8 justify-between max-h-[800px]">
          {/* Start Percentage */}
          <div className="form-group">
            <ToolTipLabel
              labelText="Start"
              htmlFor="start_percentage"
              toolTipText={"Controls the percentage of keywords to start with."}
              elValue={`${projectForm.start_percentage}%`}
            />
            <input
              type="range"
              name="start_percentage"
              id="start_percentage"
              min="0"
              max="100"
              step="0.01"
              value={projectForm.start_percentage}
              onChange={handleInputChange}
            />
          </div>

          {/* Outcome Percentage */}
          <div className="form-group">
            <ToolTipLabel
              labelText="Outcome"
              htmlFor="outcome_percentage"
              toolTipText={"Controls the percentage of keywords to end with."}
              elValue={`${projectForm.outcome_percentage}%`}
            />
            <input
              type="range"
              name="outcome_percentage"
              id="outcome_percentage"
              min="0"
              max="100"
              step="0.01"
              value={projectForm.outcome_percentage}
              onChange={handleInputChange}
            />
          </div>

          {/* OK to use percenage */}
          <div className="form-group">
            <ToolTipLabel
              labelText="OK to Use"
              htmlFor="ok_to_use_percentage"
              toolTipText={"Controls the percentage of keywords to use."}
              elValue={`${projectForm.ok_to_use_percentage}%`}
            />
            <input
              type="range"
              name="ok_to_use_percentage"
              id="ok_to_use_percentage"
              min="0"
              max="100"
              step="0.01"
              value={projectForm.ok_to_use_percentage}
              onChange={handleInputChange}
            />
          </div>

          {/* Purchase Percentage */}
          <div className="form-group">
            <ToolTipLabel
              labelText="Purchase"
              htmlFor="purchase_percentage"
              toolTipText={"Controls the percentage of keywords to purchase."}
              elValue={`${projectForm.purchase_percentage}%`}
            />
            <input
              type="range"
              name="purchase_percentage"
              id="purchase_percentage"
              min="0"
              max="100"
              step="0.01"
              value={projectForm.purchase_percentage}
              onChange={handleInputChange}
            />
          </div>

          {/* Monthly Price */}
          <div className="form-group">
            <ToolTipLabel
              labelText="Monthly Price"
              htmlFor="monthly_price"
              toolTipText={"Controls the monthly price range."}
              elValue={`$${projectForm.monthly_price}`}
            />
            <input
              type="range"
              name="monthly_price"
              id="monthly_price"
              min="0"
              max="1000"
              step="0.01"
              value={projectForm.monthly_price}
              onChange={handleInputChange}
            />
          </div>

          {/* Monthly Cost */}
          <div className="form-group">
            <ToolTipLabel
              labelText="Monthly Cost"
              htmlFor="monthly_cost"
              toolTipText={"Controls the monthly cost range."}
              elValue={`$${projectForm.monthly_cost}`}
            />
            <input
              type="range"
              name="monthly_cost"
              id="monthly_cost"
              min="0"
              max="1000"
              step="0.01"
              value={projectForm.monthly_cost}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="flex flex-col gap-4 justify-center items-center">
          <button type="button" className="reset-btn" onClick={handleReset}>Reset to default</button>
          <button type="submit" className="btn w-full" data-action="update">Save</button>
        </div>

        { loading && <div className="absolute inset-0 bg-black bg-opacity-30 grid place-items-center">
          <img className="h-16 w-16" src={spinner} alt="loading" data-testid="loading" />
        </div>}
        
      </form>
    </details>
  );
}

export default ChartSettings;