import { useState, useEffect } from "react";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

import './CompaniesPagination.css';

function CompaniesPagination({ loading, error, currentPage, setCurrentPage, totalPages }) {
  const [selectedPage, setSelectedPage] = useState(currentPage);

  const handleFirstPage = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleLastPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  useEffect(() => {
    if(!loading) {
      window.scrollTo(0, 0);

      if(loading === false) {
        setSelectedPage(currentPage);
      }
    }
  }, [loading]);

  const handlePageSelect = (event) => {
    const selected = Number(event.target.value);
    setCurrentPage(selected);
    setSelectedPage(selected);
  };
  
  return (
    <div className="companies-pagination">
      <div className="space-x-8">
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1 || loading || error }
        >
          <span className="sr-only">First</span>
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>

        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1 || loading || error }
        >
          <span className="sr-only">Previous</span>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      </div>

      {loading ? (
        <div className="text-center" data-testid="loading">Loading Companies...</div>
      ) :  
      error ? (
        <div className="text-center" data-testid="error">0 of 0</div>
      ) :
      (
        <div className="flex gap-2">
        Page
        <select
          className="border rounded px-2 bg-none text-center"
          value={selectedPage}
          onChange={handlePageSelect}
          disabled={loading}
        >
          {Array.from({ length: totalPages }, (_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
        of {totalPages}
        </div>
      )}

      <div className="space-x-8">
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages || loading || error }
        >
          <span className="sr-only">Next</span>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages || loading || error }
        >
          <span className="sr-only">Last</span>
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>
    </div>
  );
}

export default CompaniesPagination;