import { useState, useEffect } from 'react';
import { $axios } from '../services/axiosInstance';

const useCompanyNews = (ticker) => {
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        setLoading(true);
        const response = await $axios.get(`/fetch_polygon_news/${ticker}/`);
        setNews(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (!ticker) {
      return;
    }

    fetchNewsData();
  }, [ticker]);

  return { news, loading, error };
};

export default useCompanyNews;
