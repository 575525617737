import KeywordItem from "../../components/KeywordItem/KeywordItem";

export const KeywordField = ({ list, type, label, placeholder, value, onChange, onAdd, onRemove, disabled = false }) => (
  <div className="form-group">
    <label htmlFor={`input-${type}`}>{label}</label>
    <div className="flex gap-2 mb-2">
      <input
        id={`input-${type}`}
        className="flex-1 disabled:opacity-40 disabled:cursor-not-allowed"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onAdd(type, value);
          }
        }}
        disabled={disabled}
      />
      <button
        className="px-4 bg-pink-500 text-white rounded disabled:opacity-40 disabled:cursor-not-allowed"
        type="button"
        onClick={() => onAdd(type, value)}
        disabled={disabled}
        data-testid={`add-${type}`}
      >
        Add
      </button>
    </div>

    <div className="keywords-group">
      {list.map((keyword, index) => (
        <KeywordItem
          keyword={keyword}
          onRemove={() => onRemove(type, index)}
          key={`${type}-${index}`}
        />
      ))}
    </div>
  </div>
);

export default KeywordField;