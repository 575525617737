import React, { useEffect } from "react";
import * as Dashboards from "@highcharts/dashboards/dashboards";

import Highcharts from "highcharts";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsExportData from "highcharts/modules/export-data";
import highchartsMore from "highcharts/highcharts-more";
import highchartsVenn from "highcharts/modules/venn";
import highchartsFunnel from "highcharts/modules/funnel";
import highchartsSunburst from "highcharts/modules/sunburst";
import highchartsHeatmap from "highcharts/modules/heatmap";
import highchartsHistogram from "highcharts/modules/histogram-bellcurve.js";
// import highchartsSeriesLabel from "highcharts/modules/series-label";
import highchartsAccessibility from "highcharts/modules/accessibility";

import DataGrid from "@highcharts/dashboards/es-modules/DataGrid/DataGrid";
import DataGridPlugin from "@highcharts/dashboards/es-modules/Dashboards/Plugins/DataGridPlugin";

import HighchartsPlugin from "@highcharts/dashboards/es-modules/Dashboards/Plugins/HighchartsPlugin";

import { useTheme } from "../../utils/ThemeManager.jsx";

// highchartsExporting(Highcharts);
highchartsExporting(Highcharts);
highchartsExportData(Highcharts);
highchartsVenn(Highcharts);
highchartsFunnel(Highcharts);
highchartsSunburst(Highcharts);
highchartsHeatmap(Highcharts);
highchartsHistogram(Highcharts);
highchartsMore(Highcharts);
highchartsAccessibility(Highcharts);
// highchartsSeriesLabel(Highcharts);

HighchartsPlugin.custom.connectHighcharts(Highcharts);
DataGridPlugin.custom.connectDataGrid(DataGrid);

Dashboards.PluginHandler.addPlugin(HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(DataGridPlugin);

const baseOptions = {
  lang: {
    thousandsSep: ",",
  },
  chart: {
    animation: false,
    styledMode: false,
    spacingTop: 20,
    spacingRight: 20,
    spacingBottom: 20,
    spacingLeft: 20,
  },
  title: {
    align: "center",
    margin: 30,
    style: {
      fontWeight: "normal",
      fontSize: "1.4em",
    },
  },
  subtitle: {
    align: "center",
  },
  legend: {
    align: "center",
    verticalAlign: "bottom",
    layout: "horizontal",
    borderWidth: 1,
    padding: 10,
    itemStyle: {
      fontSize: "0.85em",
      fontFamily: "Plus Jakarta Sans, sans-serif",
    },
  },
  xAxis: {
    gridLineWidth: 1,
    lineWidth: 0,
    tickLength: 10,
    tickWidth: 1,
    title: {
      margin: 30,
      style: {
        fontSize: "1.2em",
        fontFamily: "Inter Tight, sans-serif",
        fontWeight: "normal",
      },
    },
    labels: {
      style: {
        fontSize: ".85rem",
        fontFamily: "Inter Tight, sans-serif",
      },
    },
  },
  yAxis: {
    title: {
      margin: 30,
      style: {
        fontSize: "1.2em",
        fontFamily: "Inter Tight, sans-serif",
        fontWeight: "normal",
      },
    },
    gridLineWidth: 1,
    tickLength: 7,
    tickWidth: 7,
    labels: {
      style: {
        fontSize: ".85em",
        fontFamily: "Inter Tight, sans-serif",
      },
    },
    stackLabels: {
      style: {
        textOutline: "none",
      },
    },
  },
  tooltip: {
    shadow: false,
    borderWidth: 2,
    style: {
      fontFamily: "Inter Tight, sans-serif",
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    line: {
      lineWidth: 2,
      marker: {
        enabled: true,
        symbol: "circle",
        lineColor: '#020021',
        lineWidth: 2,
        radius: 5,
      },
    },
    column: {
      dataLabels: {
        // remove outline from data labels
        textOutline: "none",
        style: {
        },
      },
    }
  },
};

const darkTheme = {
  ...baseOptions,
  chart: {
    ...baseOptions.chart,
    backgroundColor: null,
  },
  title: {
    ...baseOptions.title,
    style: {
      ...baseOptions.title.style,
      color: "#E6EFF8",
    },
  },
  subtitle: {
    ...baseOptions.subtitle,
    style: {
      color: "#C0D7ED",
    },
  },
  legend: {
    ...baseOptions.legend,
    borderColor: "rgba(5, 97, 183, 0.5)",
    backgroundColor: "transparent",
    itemStyle: {
      ...baseOptions.legend.itemStyle,
      color: "#C0D7ED",
    },
    itemHoverStyle: {
      color: "#FF3C6D",
    },
  },
  xAxis: {
    ...baseOptions.xAxis,
    gridLineColor: "rgba(5, 97, 183, 0.5)",
    lineColor: "rgba(5, 97, 183, 0.5)",
    tickColor: "rgba(5, 97, 183, 0.5)",
    title: {
      ...baseOptions.yAxis.title,
      style: {
        ...baseOptions.yAxis.title.style,
        color: "#C0D7ED",
      },
    },
    labels: {
      ...baseOptions.xAxis.labels,
      style: {
        ...baseOptions.xAxis.labels.style,
        color: "#E6EFF8",
      },
    },
    stackLabels: {
      enabled: true,
      textOutline: "none",
      formatter: function () {
        // dollar sign and 2 decimal places
        return formatCurrency(this.total, 0);
      },
      style: {
        color: '#3AE500',
        textOutline: "none",
      }
    },
  },
  yAxis: {
    ...baseOptions.yAxis,
    gridLineColor: "rgba(5, 97, 183, 0.5)",
    lineColor: "rgba(5, 97, 183, 0.5)",
    tickColor: "rgba(5, 97, 183, 0.5)",
    title: {
      ...baseOptions.yAxis.title,
      style: {
        ...baseOptions.yAxis.title.style,
        color: "#C0D7ED",
      },
    },
    labels: {
      ...baseOptions.yAxis.labels,
      style: {
        ...baseOptions.yAxis.labels.style,
        color: "#E6EFF8",
      },
    },
    stackLabels: {
      style: {
        color: '#3AE500',
      }
    }
  },
  tooltip: {
    ...baseOptions.tooltip,
    backgroundColor: "#020021",
    style: {
      ...baseOptions.tooltip.style,
      color: "#E6EFF8",
    },
  },
  plotOptions: {
    ...baseOptions.plotOptions,
    column: {
      style: {
        textOutline: "none",
      }
    }
  }

};

const lightTheme = {
  ...baseOptions,
  chart: {
    ...baseOptions.chart,
    backgroundColor: null,
  },
  title: {
    ...baseOptions.title,
    style: {
      ...baseOptions.title.style,
      color: "#020021",
    },
  },
  subtitle: {
    ...baseOptions.subtitle,
    style: {
      color: "#020021",
    },
  },
  legend: {
    ...baseOptions.legend,
    borderColor: "rgba(2, 0, 33, 0.17)",
    backgroundColor: "rgba(255, 255, 255, 1)",
    itemStyle: {
      ...baseOptions.legend.itemStyle,
      color: "#020021",
    },
    itemHoverStyle: {
      color: "#FF3C6D",
    },
  },
  xAxis: {
    ...baseOptions.xAxis,
    gridLineColor: "rgba(2, 0, 33, 0.17)",
    lineColor: "rgba(2, 0, 33, 0.17)",
    tickColor: "rgba(2, 0, 33, 0.17)",
    title: {
      ...baseOptions.yAxis.title,
      style: {
        ...baseOptions.yAxis.title.style,
        color: "#020021",
      },
    },
    labels: {
      ...baseOptions.xAxis.labels,
      style: {
        ...baseOptions.xAxis.labels.style,
        color: "#020021",
      },
    },
    stackLabels: {
      enabled: true,
      textOutline: "none",
      formatter: function () {
        // dollar sign and 2 decimal places
        return formatCurrency(this.total, 0);
      },
      style: {
        color: '#020021',
        textOutline: "none",
      }
    },
  },
  yAxis: {
    ...baseOptions.yAxis,
    gridLineColor: "rgba(2, 0, 33, 0.17)",
    lineColor: "rgba(2, 0, 33, 0.17)",
    tickColor: "rgba(2, 0, 33, 0.17)",
    title: {
      ...baseOptions.yAxis.title,
      style: {
        ...baseOptions.yAxis.title.style,
        color: "#020021",
      },
    },
    labels: {
      ...baseOptions.yAxis.labels,
      style: {
        ...baseOptions.yAxis.labels.style,
        color: "#020021",
      },
    },
    stackLabels: {
      style: {
        color: "#020021",
      }
    }
  },
  tooltip: {
    ...baseOptions.tooltip,
    backgroundColor: "#FFFFFF",
    style: {
      ...baseOptions.tooltip.style,
      color: "#020021",
    },
  },
  ...baseOptions.plotOptions
};

const options = {
  dark: darkTheme,
  light: lightTheme,
};


export default function ChartDashboard({ config, id = "container", async }) {
  const { theme } = useTheme();

  useEffect(() => {
    Highcharts.setOptions(options[theme]);
    Dashboards.board(id, config, async);
  }, [theme, config]);

  return <div id={id} className={`highcharts-${theme}`} data-testid="chart-dashboard"></div>;
}
