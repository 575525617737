import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import Highcharts from "highcharts";
import { generateGradientColors } from "../../../../utils/chartUtils.js";

import { useTheme } from "../../../../utils/ThemeManager.jsx";

require('highcharts/modules/wordcloud.js')(Highcharts);

// data: project.wordcloud_data
const Wordcloud = ({ data }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (data == null || data.length === 0) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if (data == null || data.length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Wordcloud data is not available.</p>
  }

  return (
    <ChartDashboard
      config={options}
      async={true}
      id="word-cloud-container"
    />
  );
};

export default Wordcloud;

const chartOptions = (data, theme) => {
  const colors = {
    dark: generateGradientColors("#FF3C6D", "#A79AC7", 10),
    light: generateGradientColors('#0561B7', '#020021', 10),
  }

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "word-cloud-cell",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: 'word-cloud-cell',
        chartOptions: {
          colors: colors[theme],
          chart: {
            styledMode: false,
            height: 450
          },
          accessibility: {
              screenReaderSection: {
                  beforeChartFormat: '<h5>{chartTitle}</h5>' +
                      '<div>{chartSubtitle}</div>' +
                      '<div>{chartLongdesc}</div>' +
                      '<div>{viewTableButton}</div>'
              }
          },
          series: [{
              type: 'wordcloud',
              data: data,
              name: 'Occurrences'
          }],
          title: {
              text: null
          },
          subtitle: {
              text: 'Weighted based on search volume average, square-root scaled',
              align: 'left',
              verticalAlign: 'bottom',
          },
          tooltip: {
              headerFormat: '<span style="font-size: 16px"><b>{point.key}</b></span><br>',
              pointFormatter: function () {
                return formatWordcloudTooltip(this, colors[theme]);
            }
          }
      }
      },
    ],
  };
};

// tooltip pointFormatter function
export const formatWordcloudTooltip = function(point, colors) {
  const colorIndex = point.colorIndex || 0;
  const color = colors[colorIndex];
  const coloredDot = `<span style="color:${color};">● </span>`;
  const formattedOriginalValue = Highcharts.numberFormat(point.original_value, 0);

  return `${coloredDot}<b> Search Volume:</b> ${formattedOriginalValue}`;
};