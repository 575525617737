import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import "./ProjectFilter.css";

function ProjectFilter({ selectedDifficulties, handleToggle }) {
  return (
    <div className="project-filter">
      <label className="mb-2 text-xs">Difficulty Filter</label>
      <div className="filter-btn-container">
        {Object.keys(selectedDifficulties).map((difficulty) => (
          <button
            key={difficulty}
            className={`filter-btn ${difficulty} ${
              selectedDifficulties[difficulty] ? "active" : ""
            }`}
            onClick={() => handleToggle(difficulty)}
            title={`Filter by ${difficulty} difficulty`}
          >
            {selectedDifficulties[difficulty] ? (
              <FontAwesomeIcon size="xl" icon={faCircleCheck} />
            ) : (
              <FontAwesomeIcon size="xl" icon={faCircle} />
            )}
            <span className="sr-only">{difficulty} difficulty</span>
          </button>
        ))}
      </div>
    </div>
  );
}

export default ProjectFilter;
