import axios from 'axios';
import { getAuth } from "firebase/auth";
import session from '../stores/session'

export const isTokenExpired = (expirationTime) => {
  // Convert the expirationTime to a Date object
  const expirationDate = new Date(expirationTime);

  // Get the current time
  const currentTime = new Date();

  // Compare the expirationDate with the current time
  // If the expirationDate is in the past, the token has expired
  return expirationDate.getTime() < currentTime.getTime();
};

// Function to check if the user has a non-expired token
export const hasNonExpiredToken = (user) => {
  if (!user) {
    return false; // No user, no token
  }

  const { stsTokenManager } = user;
  if (!stsTokenManager || !stsTokenManager.expirationTime) {
    return false; // Missing token expiration information
  }

  // Implement the isTokenExpired function as mentioned before
  const isExpired = isTokenExpired(stsTokenManager.expirationTime);

  return !isExpired;
};

// local instance
export const $axios = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}/django_api/` : 'http://localhost:8000/django_api/',
  headers: { 'Content-Type': 'application/json' },
});

// hypercorn api
export const $axios_old = axios.create({
  baseURL: 'http://localhost:4040',
  headers: {
    'Content-Type': 'application/json',
  },
});

const setupAxiosInterceptors = (_axiosInstance) => {
  // Request interceptor for API calls
  _axiosInstance.interceptors.request.use(
    request => {
      const { user } = session.getState();

      if (user && user.stsTokenManager.accessToken) {
        request.headers['Authorization'] = `Bearer ${user.stsTokenManager.accessToken}`;
      }

      return request;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor for API calls
  _axiosInstance.interceptors.response.use(
    response => response,
    async error => {
      const auth = await getAuth();
      const { clearUser, setUser } = session.getState();

      if (error  && error.response && error.response.status === 401 && !error.config.__isRetryRequest) {
        return new Promise(async(resolve, reject) => {
          console.log('Token expired, attempting to refresh...');
            error.config.__isRetryRequest = true;

            try {
              const currentUser = auth.currentUser;
              if(!currentUser) {
                clearUser();
                reject(error);
              }
  
              const newAccessToken = await currentUser.getIdToken(true);
              if(newAccessToken) {
                // reset the token in the axios config
                error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // update the user object
                await auth.updateCurrentUser(currentUser);

                console.log('User session refreshed');
  
                setUser(currentUser);
  
                // retry request
                return resolve(_axiosInstance(error.config));
              } else { 
                throw new Error('Unable to refresh token');
              }
            } catch (refreshError) {
              clearUser();
              console.error('Error refreshing token', refreshError);
              sessionStorage.setItem('redirectPath', window.location.pathname)
              return reject(refreshError);
            }
        })
      }

      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
