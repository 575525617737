import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";

import { formatCurrency } from "../../../../utils/tableUtils.js";
import { brandSort, conditionSort, generateGradientColors } from "../../../../utils/chartUtils.js";

import { useTheme } from "../../../../utils/ThemeManager.jsx";

import "./AnnualForecastByCategory.css";

// data: project.keyword_universe
function AnnualForecastByCategory({ data, category_type }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({
    pie: {},
    bar: {},
  });
  const categories = Object.entries(data)
    .filter(([key, value]) => value.category_type === category_type)
    .sort((a, b) => {
      if (category_type === 'Branded') {
        return brandSort[a[0]] - brandSort[b[0]];
      } else {
        return conditionSort[a[0]] - conditionSort[b[0]];
      }
    })
    .map(([key]) => key);

    const category_color_grades = {
      "Branded": 6,
      "Condition": 7,
    }

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    const colors = theme === 'dark' 
      ? generateGradientColors('#FCFCFC', '#020021', category_color_grades[category_type])
      : generateGradientColors('#C4D6EB', '#0561B7', category_color_grades[category_type]);

    setOptions({
      pie: pieOptions(data, categories, category_type, colors, `cost-purchase-${category_type}`),
      bar: barOptions(data, categories, category_type, colors, `profit-${category_type}`),
    });

  }, [theme]);

  if (categories.length === 0) {
    return (
      <div className="forecast-branded h-[450px] grid place-items-center">
        <p>Forecast by { category_type } data is not available for this project.</p>
      </div>
    );
  }

  return (
    <div className="forecast-branded">
      <div className="">
        <h3 className="font-bold">Annual Forecast by { category_type } Category</h3>
      </div>

      <div className="content">
        <div className="col col-1">
          <div>
            <h4 className="text-center font-bold p-2">
              { category_type } Profit Estimate
            </h4>
            <p className="text-center text-2xl flex-1 grid place-items-center pt-4">
              { formatCurrency(data['Brand Subtotal'].total_annual_profit, 0) }
            </p>
          </div>

          <div>
            <h4 className="text-center font-bold p-2">
              Profit by { category_type } Category
            </h4>
            <div className="pie-container">
              <ChartDashboard config={options.pie} id={`cost-purchase-${category_type}-container`} />
            </div>
          </div>
        </div>
        <div className="col col-2">
          <ChartDashboard config={options.bar} id={`profit-${category_type}-container`} />
        </div>
      </div>
    </div>
  );
}

export default AnnualForecastByCategory;

const pieOptions = (data, categories, category_type, colors, name) => {
  const totalCost = categories.reduce((sum, category) => {
    return sum + (data[category]?.total_annual_profit || 0);
  }, 0);

  const pieData = categories.map(category => {
    const cost = data[category]?.total_annual_profit || 0;
    return {
      name: category.split(' - ')[0],
      y: (cost / totalCost) * 100, 
    };
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          colors: colors,
          chart: {
            type: "pie",
            styledMode: false,
            height: 232,
            marginBottom: 10,
            borderWidth: 0,
          },
          title: {
            text: null,
          },
          tooltip: {
            enabled: true,
            formatter: function () {
              const dollarValue = data[this.point.name]?.total_annual_profit || 0;
              return `<b>${this.point.name}</b>: ${formatCurrency(dollarValue, 0)}`;
            },
          },
          subtitle: {
            text: "",
          },
          plotOptions: {
            pie: {
              dataLabels: [
                {
                  distance: -30,
                  enabled: true,
                  // format: "{point.percentage:.1f} %",
                  formatter: function () {
                    // dont display labels for small slices
                    return this.point.percentage > 5 ? `${this.point.percentage.toFixed(1)}%` : null;
                  },
                  style: {
                    color: 'contrast',
                    textOutline: "1px solid contrast",

                  }
                },
              ],
            },
          },
          series: [
            {
              name: "Percentage",
              type: "pie",
              data: pieData,
            },
          ],
        },
      },
    ],
  };
};

const barOptions = (data, categories, category_type, colors, name) => {
  const barDataRevenue = categories.map(category => {
    return data[category]?.total_annual_revenue || 0;
  });

  const barDataProfit = categories.map(category => {
    return data[category]?.total_annual_profit || 0;
  });

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: `${name}-keywords-cell`,
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: `${name}-keywords-cell`,
        chartOptions: {
          colors: [colors[0], colors[colors.length - 1]],
          chart: {
            type: "bar",
            styledMode: false,
            height: 400,
          },
          title: {
            text: `Revenue and Profit by ${ category_type } Category`,
          },
          xAxis: {
            categories: categories,
            title: {
              text: null,
            },
          },
          yAxis: {
            min: 0,
            title: {
              text: null,
            },
            title: {
              text: "Dollars (millions)",
            },
          },
          tooltip: {
            valueSuffix: " millions",
            // formatter 2 decimal places
            formatter: function () {
              return (
                "<b>" +
                this.x +
                "</b><br>" +
                this.series.name +
                ": <b>" + '$' +
                Highcharts.numberFormat(this.y, 0) +
                "</b>"
              );
            },
          },
          plotOptions: {
            bar: {
              borderRadius: "50%",
              dataLabels: {
                enabled: true,
                // format rounded to 0 decimal places
                formatter: function () {
                  return Highcharts.numberFormat(this.y, 0);
                },
              },
              groupPadding: 0.1,
            },
          },
          series: [
            {
              name: "Revenue",
              data: barDataRevenue,
            },
            {
              name: "Profit",
              data: barDataProfit,
            },
          ],
        },
      },
    ],
  };
};
