import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard";

import { formatPercent } from "../../../../utils/tableUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

import './ConditionOpportunity.css';

// data: project.condition_analysis
function ConditionOpportunity({ data }) {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    setOptions(chartOptions(data.population_chart_input, theme));
  }, [theme]);

  if (!data || Object.keys(data).length === 0 || !data.population_chart_input) {
    return <p className="min-h-[450px] px-4 py-8 font-bold grid place-content-center">Search Spend Rec data is not available.</p>
  }

  return <div className="condition-opportunity">
    <div className="content">
      <div className="col col-1">
        <h4 className="text-center font-bold p-2">People with Condition</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          { 
            data.condition_data?.total_population ? data.condition_data.total_population.toLocaleString() : 'N/A' 
          }
        </p>
        <h4 className="text-center font-bold p-2">Estimated People Untreated</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          {/* untreated_population */
            data.condition_data?.untreated_population ? data.condition_data.untreated_population.toLocaleString() : 'N/A'
          }
        </p>
        <h4 className="text-center font-bold p-2">Percent Untreated</h4>
        <p className="text-center text-2xl flex-1 grid place-items-center">
          {/* pct_untreated */
            data.condition_data?.pct_untreated ? formatPercent(data.condition_data.pct_untreated, 0) : 'N/A'
          }
        </p>
      </div>
      <div className="col col-2">
        <ChartDashboard config={options} async={true} id="condition-opportunity-container" />
      </div>
    </div>
  </div>
} 

export default ConditionOpportunity;

// chart options
const chartOptions = (data, theme) => {
  const series = [];
  const colors = {
    dark: ["#FF7347", "#3AE500"],
    light: ['#0561B7', '#FF7347'],
  }

  // Check if the first series exists and is valid
  if (data.labels[0] && data.data1 && data.data1.length > 0) {
    series.push({
      name: data.labels[0],
      data: data.data1,
      marker: {
        fillColor: theme === 'dark' ? '#020021' : '#FFFFFF',  
        lineColor: colors[theme][0]
      },
    });
  }
  
  // Check if the second series exists and is valid
  if (data.labels[1] && data.data2 && data.data2.length > 0) {
    series.push({
      name: data.labels[1],
      data: data.data2,
      marker: {
        fillColor: theme === 'dark' ? '#020021' : '#FFFFFF',
        lineColor: colors[theme][1]
      },
    });
  }
  return {
  gui: {
    layouts: [
      {
        rows: [
          {
            cells: [
              {
                id: "population-cell-0",
              },
            ],
          },
        ],
      },
    ],
  },
  components: [
    {
      type: "Highcharts",
      cell: "population-cell-0",
      chartOptions: {
        colors: colors[theme] || colors.dark,
        chart: {
          height: 440,
          styledMode: false,
        },
        title: {
          text: data.title || null,
          align: "center",
        },
        subtitle: {
          text: data.subtitle || null,
          align: "center",
        },
        yAxis: {
          title: {
            text: data.yAxisText || null,
          },
        },
        xAxis: {
          categories: data.year || [],
        },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "bottom",
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 768,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        series: series,
      },
    },
  ],
  }
};