import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Highcharts from "highcharts";
import ChartDashboard from "../../../components/ChartDashboard/ChartDashboard.jsx";

import { useTheme } from "../../../utils/ThemeManager.jsx";

// data: // data: cluster.visualization.search_volume_by_type
// Search Volume by Month by Type
const SearchVolMonth = ({ data, project_name, history, clusterId }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(chartOptions(data, project_name, theme, navigate, history, clusterId));
  }, [theme, data]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <p className="min-h-[450px] px-4 py-8 font-bold grid place-items-center">
        Monthly Search Volume data is not available.
      </p>
    );
  }

  return (
    <ChartDashboard
      config={options}
      async={true}
      id="monthly-search-volume-bubble"
    />
  );
};

export default SearchVolMonth;

const chartOptions = (data, project_name, theme, navigate, history, clusterId) => {
  const colors = {
    dark: ['#FF3C6D'],
    light: ["#0561B7"],
  };

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "monthly-search-volume-bubble-cell",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "monthly-search-volume-bubble-cell",
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "bubble",
            zoomType: "xy",
            plotBorderWidth: 1,
            styledMode: false,
            height: 450,
          },
          title: {
            text: project_name ? `${project_name} Monthly Search Volume by Type` : "Monthly Search Volume by Type",
          },
          subtitle: {
            text: "Source: SEMRush",
            // name of cluster?
          },
          legend: {
            enabled: false,
          },
          xAxis: {
            title: {
              text: "Average Monthly Search Volume",
            },
          },
          yAxis: {
            title: {
              text: "Keyword Difficulty",
            },
          },
          tooltip: {
            useHTML: true,
            formatter: function () {
              return `<div style="display: flex; flex-direction: column;">
                <strong style="margin-bottom: 4px">${this.point.title}</strong>
                <div style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr; column-gap: 8px">
                  <div>Monthly Volume:</div> <div>${Highcharts.numberFormat(this.point.x, 0)}</div>
                  <div>KWD // KWO:</div> <div>${Highcharts.numberFormat(this.point.kwDifficulty, 2)}% // ${Highcharts.numberFormat(this.point.kwOpportunity, 2)}</div>
                </div>
              </div>`;
            },
          },
          series: [
            {
              name: "Cluster Share",
              data: data,
              marker: {
                fillOpacity: 0.6,
              },
              colorByPoint: true,
            },
          ],
          plotOptions: {
            bubble: {
              minSize: 3,
              maxSize: 50,
              point: {
                events: {
                  click: function () {
                    if (this.uuid) {
                      // Use react-router-dom navigate function to navigate in-app
                      navigate(`/cluster/${this.uuid}`, { state: { history: [
                        ...history,
                        {name: "Cluster Feed", path: `/clusters/${clusterId}`},

                      ]}});
                    } else {
                      console.log("No UUID found for this cluster.");
                    }
                  },
                },
              },
            },
          },
          annotations: [
            {
              labels: [
                {
                  point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 16800,
                    y: 98,
                  },
                  text: "Search Volume",
                },
              ],
              draggable: "",
            },
            {
              labels: [
                {
                  point: {
                    xAxis: 0,
                    yAxis: 0,
                    x: 4000,
                    y: 25,
                  },
                  text: "Low Difficulty",
                },
              ],
              labelOptions: {
                style: {
                  fontWeight: "bold",
                },
              },
              draggable: "",
            },
          ],
        },
      },
    ],
  };
};
