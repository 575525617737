import { useLocation } from "react-router-dom";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";

import "./ConditionAnalysis.css"

const ConditionAnalysis = () => {
  const { project } = useLocation().state;

  return (
    <div className="condition-analysis-page page-base space-y-8">
      <Breadcrumbs project={project} currentPage="Condition Analysis" />

      <section className="page-section">
        <h1 className="section-header">{project && project.name}</h1>
        
        <div className="p-4">
          <p className="text-lg">Condition Analysis page is not implemented yet</p>
        </div>
      </section>

    </div>
  );
}

export default ConditionAnalysis;