import ProjectList from "../../components/ProjectList/ProjectList"

import { useAppContext } from "../../context/AppContext";

export const filterUpdatingProjects = (updatingProjects, companyUuid) => {
  return updatingProjects.filter((p) => p.parent_company === companyUuid);
};

const CompanyItem = ({ company, selectedDifficulties }) => {
  // list of apps in queue
  const { updatingProjects } = useAppContext();
  const filteredUpdatingProjects = filterUpdatingProjects(updatingProjects, company.uuid);

  return (
    <li className="page-section company" key={`${company.uuid}`}>
      <div className="table-container overflow-x-scroll w-full">
        <ProjectList
          company={company}
          projects={company.brand_project}
          selectedDifficulties={selectedDifficulties}
          projectStatus={filteredUpdatingProjects}
          withLink={true}
        />
      </div>
    </li>
  )
}

export default CompanyItem