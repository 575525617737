import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

function Pagination({ next, previous, currentPage, totalPages, onNext, onPrevious }) {

  return (
    <div className="pagination">
      <button onClick={onPrevious} disabled={!previous}>
        <span className="sr-only">Previous</span>
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>

      <div className="text-lg font-bold space-x-4">{ currentPage } <div className=" font-normal text-sm inline-block mx-2">of</div> { totalPages }</div>

      <button onClick={onNext} disabled={!next}>
        <span className="sr-only">Next</span>
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </div>
  );
}

export default Pagination;