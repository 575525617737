import Markdown from "react-markdown";

import SWOTGraphic from "./SWOTGraphic";

import './SWOTAnalysis.css';

const SWOTAnalysis = ({ swotHeadlines, swotAnalysis }) => {
  return <>
    <div className="swot-analysis">
      <div className="w-full hidden md:mb-4 md:block">
        { Object.values(swotHeadlines).every(arr => arr.length > 0)
          ? <SWOTGraphic swotHeadlines={swotHeadlines} />
          : <p className="p-4 md:p-8 text-center font-bold">SWOT Headlines not available.</p>
        }
      </div>
      
      <div className="swot-text">
        { swotAnalysis && swotAnalysis.length > 0 
          ? (
          <Markdown disallowedElements={['code', 'pre']}>{
            swotAnalysis
              ?.replace('Strengths:', 'Strengths')
              .replace('Weaknesses:', 'Weaknesses')
              .replace('Opportunities:', 'Opportunities')
              .replace('Threats:', 'Threats')
              .replace(/<br>/g, "\n")
          }</Markdown>
        ) : null
        }
      </div>
    </div>
  </>
};

export default SWOTAnalysis;