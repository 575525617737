import InfoBox from "./InfoBox";

// fontawesome
import {
  faAward,
  faChartSimple,
  faClipboardList,
  faGears,
} from "@fortawesome/free-solid-svg-icons";

import './ACNUInfo.css';

export const formatDate = (dateString) => {
  if (dateString == null || dateString === "") return "N/A";

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function ACNUInfo({ project, loading }) {
  return <div className="acnu-info">
      <InfoBox 
        icon={faClipboardList} 
        title="Condition" 
        value={project?.condition_analysis_name || "N/A"}
        loading={loading}
        to={`/project/${project?.uuid}/condition-analysis`}
        project={project}
      />
      
      <InfoBox 
        icon={faChartSimple} 
        title="ACNU Difficulty" 
        value={project?.acnu_difficulty || "N/A"}
        loading={loading}
      />

      <InfoBox 
        icon={faAward} 
        title="Approval Date" 
        value={formatDate(project?.most_recent_approval)}
        loading={loading}
      />

      <InfoBox 
        icon={faGears} 
        title="Last Run" 
        value={formatDate(project?.last_run)}
        loading={loading}
        to="/project-status"
      />
    </div>;
}

export default ACNUInfo;