import './Progress.css';

function Progress({ id, label, value, max, height = '40' }) {
  return <div className="progress">
    <label htmlFor={id}>{ label }</label>
    <div className="progress-container">
      <progress 
        id={id} 
        className={`progress-bar`}
        style={{ '--progress-height': `${height}px` }}
        value={value} 
        max={max}
        aria-valuenow={value}
        aria-valuemin="0"
        aria-valuemax={max}
        aria-label={`${label}: ${value} out of ${max}`}
      />

      <p className="progress-value" aria-hidden="true">{ value }%</p>
    </div>
  </div>
}

export default Progress;