import { Link } from "react-router-dom";

import './Admin.css';

function Admin() {
  return <>
    <div className="page-base admin-page dashboard">
      <section className="page-section">
        <h1 className="section-header">Admin Dashboard</h1>
        <div className="p-4 flex flex-col md:flex-row gap-4">
          <Link className="btn flex-1" to="/admin/keyword-categories">Keyword Categories</Link>
          <Link className="btn flex-1" to="/admin/library-catalog">Library Catalog</Link>
        </div>
      </section>

    </div>
  </>
}

export default Admin;