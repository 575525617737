import useCompanyNews from '../../hooks/useCompanyNews';
import NewsItem from './NewsItem';

import './NewsFeed.css';

const CompanyNews = ({ ticker }) => {
  const { news, loading, error } = useCompanyNews(ticker);

  if (loading) return <p className='px-4 py-8 md:p-8 text-center'>Fetching company news...</p>;
  if (error) return <p className='px-4 py-8 md:p-8 text-center'>Error fetching company news</p>;

  if(!news) return <p>Loading...</p>;

  return (
    <div className="news-list-container">
      <ul>
        { news.length > 0 
          ? news.map((n, index) => <NewsItem key={n.id || index} news={n} />) 
          : <p className='px-4 py-8 md:p-8 text-center md:col-span-2 lg:col-span-3'>No news found</p>
        }
      </ul>
    </div>
  )
}

export default CompanyNews;