import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import '../Admin.css';

function LibraryCatalog() {
  return <>
    <div className="page-base admin-page">
    <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-watermelon-500">
            Library Catalog
          </li>
        </ol>
      </nav>

      <section className="page-section admin-links">
        <h1 className="section-header">Library Catalog</h1>


        <div className="p-4 flex flex-col md:flex-row gap-4">
          <Link className="btn flex-1" to="/admin/condition-library">Condition Library</Link>
          <Link className="btn flex-1" to="/admin/keyword-universe">Keyword Universe</Link>
          <Link className="btn flex-1" to="/admin/forecast-leaderboard">Forecast Leaderboard</Link>
        </div>
      </section>
    </div>
  </>
}

export default LibraryCatalog;