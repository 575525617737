// react
import { Link } from "react-router-dom";

// global components
import RefreshButton from "../RefreshButton/RefreshButton";

// local components
import ProjectDifficultyIcon from "./ProjectDifficultyIcon";
import ProjectStatusIcon from "./ProjectStatusIcon";

// styles
import "./ProjectList.css";

const ProjectList = ({ company, projects = [], selectedDifficulties, projectStatus = [], withLink, withHeader }) => {
  // Add an isUpdating property to projects without mutating the original projects array
  const updatedProjects = projects.map(project => {
    // Find the matching project status by comparing the 'uuid' instead of 'id'
    const matchedStatus = projectStatus.find(status => status.uuid === project.uuid);

    // If there's a match, merge the properties of both objects
    if (matchedStatus) {
      return {
        ...project,
        ...matchedStatus
      };
    }

    return project;  // Otherwise, return the original project
  });

  // Filter projects based on selected difficulties
  const filteredProjects = updatedProjects.filter(project =>
    selectedDifficulties[project.acnu_difficulty] || Object.values(selectedDifficulties).every(v => !v) || !project.acnu_difficulty
  );

  return <div className="rounded overflow-hidden">
    <table className="project-table">
      <thead>
        <tr>
          <th>
            { withLink 
              ? <Link 
                to={`/company/${ company.uuid }`}
                state={{
                  from: window.location.pathname,
                  history: [
                    { 
                      name: company.name,
                      path: window.location.pathname === '/companies'
                        ? '/companies'
                        : `/company/${ company.uuid }`
                    }
                  ]
                }}
                >
                  <h2 className="text-[#C0D7ED]"><span className="sr-only">projects for</span> { company.name }</h2>
                </Link>
              : withHeader
                ? <h2 className="text-2xl text-[#C0D7ED]">Projects</h2>
                : 'Projects'
            }
          </th>

          <th className="hidden md:flex md:justify-start md:items-center md:gap-4">
            ACNU Difficulty
          </th>

          <th className="flex justify-start items-center gap-4">
              <div className="w-full hidden md:block">Last Run</div>
              <div className="w-full block md:hidden text-center">Status</div>
          </th>
        </tr>
      </thead>
      <tbody>
        { filteredProjects.map((project, index) => (
            <tr key={project.uuid || index} className={ project.last_ran === 'in progress' ? 'animate-pulse' : ''} data-testid={`refresh-${project.uuid}`}>
              <td>
                <div className="project-header">
                <RefreshButton 
                    refresh_property="project"
                    project={project}
                  />
                  <div className="space-y-1.5">
                  <h3>
                    {
                      project.condition_analysis_name 
                      ? <Link 
                        to={`/project/${project.uuid}`}
                        state={{ 
                          from: window.location.pathname,
                          history: [
                            {
                              name: 'Companies',
                              path: '/companies'
                            },
                            { 
                              name: company.name,
                              path: `/company/${ company.uuid }`
                            },
                          ]
                        }}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        {project.name} 
                      </Link>
                      : project.name
                    }

                  </h3>
                  { project.condition_analysis_name 
                    ? <div className="ml-1 inline-flex py-[3px] px-2 text-xs dark:text-white bg-[#F0F0F0] dark:bg-transparent border border-[#35334D] dark:border-watermelon-500 border-dotted">
                      <span>{project.condition_analysis_name}</span>
                    </div>
                    : <div className="ml-1 inline-flex py-[3px] px-2 text-xs text-[#B2294B] dark:text-white bg-white dark:bg-[#B2294B] border border-[#B2294B] dark:border-white">
                    <span>unavailable</span>
                  </div>}
                  </div>
                </div>
              </td>

              <td className="hidden md:block">
                <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
              </td>
              <td>
                <div className="flex items-center justify-center md:justify-start gap-2">
                  <div className="icon-bg">
                    <ProjectStatusIcon status={project.processing_status} />
                  </div>
                  
                  <span className="hidden md:block">
                    {
                      project.last_run
                      ? new Date(project.last_run).toLocaleDateString() 
                      : 'Never'
                    }
                  </span>
                </div>
              </td>
              <td className="spacer" role="presentation" aria-hidden="true"></td>
            </tr>
          )) 
        }
      </tbody>
    </table>
    </div>;
};

export default ProjectList;