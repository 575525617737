const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECT_DATA':
      return {
        ...state,
        ...action.projectData,
        negative_words: Array.isArray(action.projectData.negative_words) ? action.projectData.negative_words : [],
        start_percentage: action.projectData.start_percentage || 0,
        outcome_percentage: action.projectData.outcome_percentage || 0,
        ok_to_use_percentage: action.projectData.ok_to_use_percentage || 0,
        purchase_percentage: action.projectData.purchase_percentage || 0,
        monthly_price: action.projectData.monthly_price || 0,
        monthly_cost: action.projectData.monthly_cost || 0,

      };
    case 'UPDATE_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_LIST':
      return { ...state, [action.field]: action.value };
    case 'UPDATE_LIST':
      return { ...state, [action.field]: [...state[action.field], action.value] };
      case 'REMOVE_FROM_LIST':
        return {
          ...state,
          [action.field]: Array.isArray(state[action.field]) ? state[action.field].filter((item, i) => i !== action.index) : []
        };
    case 'UPDATE_KEYWORD_FILTERS':
      return {
        ...state,
        keyword_filters: { ...state.keyword_filters, [action.field]: action.value },
      };
    case 'UPDATE_GOALS_MARKETING':
      return { ...state, goals_marketing: action.value };
    default:
      return state;
  }
};

export default formReducer;