import "./NewsItem.css";

const NewsItem = ({ news }) => {
  return (
    <li>
      <a title={news.title} className="page-section news-item" href={news.article_url} target="_blank" rel="noreferrer">
        <div className="news-image">
          <img src={news.image_url} alt={news.title} referrerPolicy="no-referrer" />
        </div>

        <div className="news-body">
          <h3>{news.title.length > 65 ? `${news.title.substring(0, 65)}...` : news.title}</h3>

          <div>
            <p className="leading-snug author">{news.author}</p>
            <p className="leading-snug published">
              { news.published_utc
                ? new Date(news.published_utc).toLocaleDateString()
                : "Date not available"
              }
            </p>
          </div>
        </div>
      </a>
    </li>
  );
};

export default NewsItem;
