import { Link } from 'react-router-dom';

import './Missing.css';

const Missing = () => (
  <div className="missing page-base">
    <h1>404</h1>
    <p>
      The page you are looking for does not exist. Please check the URL or <Link to="/companies">return home</Link>.
    </p>
  </div>
);

export default Missing;