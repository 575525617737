// initial form state
const initialFormState = {
  // Condition
  clinical_trials: {
    uuid: null,
    name: "Clinical Trials",
    allowed: false,
    keyword_set: [],
  },
  support: {
    uuid: null,
    name: "Support",
    allowed: false,
    keyword_set: [],
  },
  preventative: {
    uuid: null,
    name: "Preventative",
    allowed: false,
    keyword_set: [],
  },
  treatment: {
    uuid: null,
    name: "Treatment",
    allowed: false,
    keyword_set: [],
  },
  diagnosis: {
    uuid: null,
    name: "Diagnosis",
    allowed: false,
    keyword_set: [],
  },
  symptoms: {
    uuid: null,
    name: "Symptoms",
    allowed: false,
    keyword_set: [],
  },
  general_condition: {
    uuid: null,
    name: "General Condition",
    allowed: false,
    keyword_set: [],
  },
  // Branded
  evaluation: {
    uuid: null,
    name: "Evaluation",
    allowed: false,
    keyword_set: []
  },
  payment: {
    uuid: null,
    name: "Payment",
    allowed: false,
    keyword_set: []
  },
  side_effects: {
    uuid: null,
    name: "Branded Side Effects",
    allowed: false,
    keyword_set: [],
  },
  usage: {
    uuid: null,
    name: "Usage",
    allowed: false,
    keyword_set: []
  },
  assistance: {
    uuid: null,
    name: "Assistance",
    allowed: false,
    keyword_set: []
  },
  general_branded: {
    uuid: null,
    name: "General Branded",
    allowed: false,
    keyword_set: [],
  },
  // Negative
  animals: {
    uuid: null,
    name: "Animals",
    allowed: false,
    keyword_set: [],
  },
  explicit: {
    uuid: null,
    name: "Explicit",
    allowed: false,
    keyword_set: [],
  },
  other_global_condition_negatives: {
    uuid: null,
    name: "Other Global Condition Negatives",
    allowed: false,
    keyword_set: [],
  },
}

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECT_DATA':
      const newState = { ...state };

      action.projectData.forEach((item) => {
        newState[item.snake_case_name] = { ...item };
      });

      return {
        ...state,
        ...newState,
      };
    case 'UPDATE_CATEGORY':
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

export { initialFormState, formReducer };