import { useState, useEffect } from 'react';
import { $axios } from '../../../services/axiosInstance';

const useClusters = (clusterId) => {
  const [clusterFeed, setClusterFeed] = useState([]);
  const [searchVol, setSearchVol] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchCluster = async () => {
      try {
        const { data } = await $axios.get(`/cluster/${clusterId}/`);
        if (data == null || data.clustering_results?.clusters?.length === 0) {
          setLoading(false);
          return;
        }

        // || [] is used because conditions still use old format
        setClusterFeed(data.clustering_results?.cluster_info || []);
        setSearchVol(data.clustering_results?.search_volume_by_type || []);
      } 
      catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCluster();
  }, [clusterId]);

  return { clusterFeed, searchVol, loading, error };
};

export default useClusters;
