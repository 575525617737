import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/rapier";
import Lights from "../../canvas/productivity/Lights";
import { Level } from "../../canvas/productivity/Level";
import Player from "../../canvas/productivity/Player";
import Effects from "../../canvas/productivity/Effects";
import useGame from "../../stores/useGame.js";

import { KeyboardControls } from "@react-three/drei";
import Interface from "../../canvas/productivity/Interface";

const ProductivityBooster = () => {
  const blocksCount = useGame((state) => state.blocksCount);
  const blocksSeed = useGame((state) => state.blocksSeed);

  return <div className="absolute inset-0">
    <KeyboardControls
      map={[
        { name: "forward", keys: ["ArrowUp", "KeyW"] },
        { name: "backward", keys: ["ArrowDown", "KeyS"] },
        { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
        { name: "rightward", keys: ["ArrowRight", "KeyD"] },
        { name: "jump", keys: ["Space"] },
      ]}
    >
      <Canvas
        shadows
        camera={{ fov: 45, near: 0.1, far: 200, position: [2.5, 4, 6] }}
      >
        <color attach="background" args={["#252731"]} />

        <Effects />

        <Physics>
          <Lights />
          <Level count={blocksCount} seed={blocksSeed} />
          <Player />
        </Physics>
      </Canvas>
      <Interface />
    </KeyboardControls>
  </div>;
};

export default ProductivityBooster;
