import KeywordItem from "../KeywordItem/KeywordItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import './KeywordField.css';

const KeywordField = ({ list = [], type, label, placeholder, value, onChange, onAdd, onRemove, disabled = false, keywordLimit = 500 }) => (
  <div className="keywords-form-group">
    { label && <label htmlFor={`input-${type}`}>{ label }</label>}

    <div className="keyword-input">
      <input 
        type="text" 
        id={`input-${type}`}
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => onChange(type, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onAdd(type, value);
          }
        }}
        disabled={disabled || list.length >= keywordLimit}
      />
      <button 
        type="button"
        onClick={() => onAdd(type, value)}
        disabled={disabled || list.length >= keywordLimit}
        data-testid={`add-${type}`}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span className="sr-only">add { type } keyword</span>
      </button>
    </div>

    <div className={`keywords-group ${disabled ? 'opacity-50' : 'opacity-100'}`}>
      { list && list.map((keyword, index) => (
        <KeywordItem 
          keyword={typeof keyword === 'object' ? keyword.value : keyword}
          onRemove={() => onRemove(type, index)}
          key={`${type}-${index}`}
          disabled={disabled}
        />
      )) }
      <div className={`keywords-count 
        ${keywordLimit && list.length >= keywordLimit - 3 && list.length < keywordLimit ? 'warning' : ''}
        ${keywordLimit && list.length === keywordLimit ? 'limit' : ''}`
      }
      >
        { list.length } / { keywordLimit }
      </div>
    </div>
  </div>
);

export default KeywordField;