// react
import { useTheme } from '../../../utils/ThemeManager.jsx'

// highcharts
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";



highchartsAccessibility(Highcharts);

const KeywordsChart = ({ data }) => {
  const { theme } = useTheme();

  if (!data || data.length === 0) {
    return <p className="px-4 py-8 font-bold text-center">Keywords data is not available</p>
  }

  return (
    <HighchartsReact 
      containerProps={{ id: "search-volume-chart", 'data-testid': 'search-volume-chart' }}
      highcharts={Highcharts} 
      options={chartOptions(data, theme)} />
  );
}

export default KeywordsChart;

export const chartOptions = (data, theme) => {
  const { brandedData, unbrandedData } = data.reduce((acc, { keyword_type, search_volume_avg, keyword_difficulty, keyword }) => {
    if (keyword_type === 'branded') {
      acc.brandedData.push({ 
        x: search_volume_avg,
        y: keyword_difficulty,
        keyword: keyword,
      });
    } else if (keyword_type === 'unbranded') {
      acc.unbrandedData.push({ 
        x: search_volume_avg,
        y: keyword_difficulty,
        keyword: keyword,
      });
    }
    return acc;
  }, { brandedData: [], unbrandedData: [] });

  if (!brandedData || brandedData.length === 0 || !unbrandedData || unbrandedData.length === 0) {
    return 
  }

  return {
    chart: {
      type: "scatter",
      zoomType: "xy",
      backgroundColor: null,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      reflow: true,
      events: {
        load: function () {
          var chart = this,
            rectWidth = chart.plotWidth / 2,
            rectHeight = chart.plotHeight / 2,
            topLeftX = chart.plotLeft,
            topLeftY = chart.plotTop,
            topRightX = chart.plotLeft + rectWidth,
            topRightY = chart.plotTop,
            bottomLeftX = chart.plotLeft,
            bottomLeftY = chart.plotTop + rectHeight,
            bottomRightX = chart.plotLeft + rectWidth,
            bottomRightY = chart.plotTop + rectHeight;
  
          // Draw rectangle for top-left quadrant
          chart.renderer.rect(
            topLeftX,
            topLeftY,
            rectWidth,
            rectHeight
          ).attr({
            fill: 'rgba(255, 99, 132, 0.2)', // Set the background color for the top-left quadrant
            zIndex: -1
          }).add();
  
          // Draw rectangle for top-right quadrant
          chart.renderer.rect(
            topRightX,
            topRightY,
            rectWidth,
            rectHeight
          ).attr({
            fill: 'rgba(255, 205, 86, 0.2)', // Set the background color for the top-right quadrant
            zIndex: -1
          }).add();
  
          // Draw rectangle for bottom-left quadrant
          chart.renderer.rect(
            bottomLeftX,
            bottomLeftY,
            rectWidth,
            rectHeight
          ).attr({
            fill: 'rgba(75, 192, 192, 0.2)', // Set the background color for the bottom-left quadrant
            zIndex: -1
          }).add();
  
          // Draw rectangle for bottom-right quadrant
          chart.renderer.rect(
            bottomRightX,
            bottomRightY,
            rectWidth,
            rectHeight
          ).attr({
            fill: 'rgba(54, 162, 235, 0.2)', // Set the background color for the bottom-right quadrant
            zIndex: -1
          }).add();
        }
      }
    },

    title: {
      text: null,
    },

    legend: {
      align: 'center',
      verticalAlign: 'top',
      layout: 'horizontal',
      itemStyle: {
        color: theme === 'dark' ? '#ffffff' : '#000000',
      }
    },

    xAxis: {
      title: {
        text: "Search Volume",
      },
    },

    yAxis: {
      title: {
        text: "Difficulty",
      },
      min: 0,
      max: 100,
    },

    tooltip: {
      style: {
        color: "white"
      },
      formatter: function () {
        return (
          '<b>' +
          this.point.keyword +
          '</b><br>search volume: ' +
          this.point.x + '<br>' +
          'competition: ' +
          this.point.y
        );
      },
    },

    series: [
      {
        name: "branded",
        color: theme === 'dark' ? '#6BE6D8' : '#FF75D3',
        data: brandedData,
        turboThreshold: 20000,
      },
      {
        name: "unbranded",
        color: theme === 'dark' ? '#E432B8' : '#AF41F0',
        data: unbrandedData,
        turboThreshold: 20000,
      },
    ],
  }
}