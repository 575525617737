import { useState, useEffect } from "react";
import { $axios } from "../../../../services/axiosInstance";

const useKeyword = (keywordId) => {
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState({});
  const [searchUrl, setSearchUrl] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchKeyword = async () => {
      try {
        const { data } = await $axios.get(`/derived_keywords_list/${keywordId}/`);
        setKeyword(data);

        if (data.value) {
          setSearchUrl(`https://www.google.com/search?q=${data.value.replace(/ /g, '+')}`);
        }
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKeyword();
  }, []);

  return { keyword, searchUrl, loading, error };
}

export default useKeyword;