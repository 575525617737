import { useEffect, useState } from "react";
import ChartDashboard from "../../../../components/ChartDashboard/ChartDashboard.jsx";
import { formatMonths, generateGradientColors } from "../../../../utils/chartUtils.js";
import { useTheme } from "../../../../utils/ThemeManager.jsx";

// data: brandData.stop_usage_activity
const StopUsageChart = ({ data }) => {
  const { theme } = useTheme();
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return;

    setOptions(chartOptions(data, theme));
  }, [theme]);

  if(!data || Object.keys(data).length === 0) return null;

  return (
    <ChartDashboard
      config={options}
      id="stop-usage-chart-container"
    />
  );
};

export default StopUsageChart;

// chart options
const chartOptions = (data, theme) => {
  const series = [];

  // Add data1 series if it exists
  if (data.data1_label && data.data1_values && data.data1_values.length > 0) {
    series.push({
      name: data.data1_label,
      data: data.data1_values,
    });
  }

  // Add data2 series if it exists
  if (data.data2_label && data.data2_values && data.data2_values.length > 0) {
    series.push({
      name: data.data2_label,
      data: data.data2_values,
    });
  }

  // Add data3 series if it exists
  if (data.data3_label && data.data3_values && data.data3_values.length > 0) {
    series.push({
      name: data.data3_label,
      data: data.data3_values,
    });
  }

  const colors = {
    dark: generateGradientColors('#020021', '#FCFCFC', series.length),
    light: generateGradientColors('#0561B7', '#C4D6EB', series.length),
  };

  return {
    gui: {
      layouts: [
        {
          rows: [
            {
              cells: [
                {
                  id: "stop-usage-cell-0",
                },
              ],
            },
          ],
        },
      ],
    },
    components: [
      {
        type: "Highcharts",
        cell: "stop-usage-cell-0",
        chartOptions: {
          colors: colors[theme],
          chart: {
            type: "column",
            styledMode: false,
            height: 450,
          },
          title: {
            text: null,
          },
          xAxis: {
            categories: formatMonths(data.xAxis_dates),
            type: "datetime",
          },
          yAxis: {
            min: 0,
            title: {
              text: "Approximate Monthly Search Volume",
            },
            stackLabels: {
              enabled: true,
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: series,
        },
      },
    ],
  };
};
