import { useEffect, useRef } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faKey, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { formatFloat } from '../../utils/tableUtils';

import Loading from '../../components/Loading/Loading';
import DynamicCrumbs from '../../components/Breadcrumbs/DynamicCrumbs';
import ErrorDisplay from '../../components/ErrorDisplay/ErrorDisplay';
import SearchVolBubble from './Visualizations/SearchVolBubble';

import useClusters from './hooks/useClusters';

import './Clusters.css';
import { useState } from 'react';

function Clusters() {
  const { clusterId } = useParams();
  const { clusterFeed, searchVol, loading, error } = useClusters(clusterId);
  const [sortConfig, setSortConfig] = useState({ key: 'total_search_volume', direction: 'descending' });
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef(null);

  const location = useLocation();
  const history = location.state?.history || [];

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return sortConfig.direction === 'ascending' 
        ? <FontAwesomeIcon className='ml-2' icon={faCaretUp} />
        : <FontAwesomeIcon className='ml-2' icon={faCaretDown} />;
    }
    return null;
  };

  const filteredClusterFeed = clusterFeed.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedClusterFeed = [...filteredClusterFeed]
    .sort((a, b) => {
      let aValue = a[sortConfig.key] || 0;
      let bValue = b[sortConfig.key] || 0;
      return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
    });

  const filteredSearchVol = searchVol.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [clusterFeed, searchVol]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <DynamicCrumbs current="Cluster Feed" />
        <ErrorDisplay error={error} />
      </div>
    );
  }

  if (clusterFeed.length === 0) {
    return (
      <div className='page-base cluster-feed space-y-4'>
        <DynamicCrumbs current="Cluster Feed" />
        <section className='page-section'>
          <h1 className='section-header'>Cluster Feed</h1>
          <div className='p-8 flex flex-col gap-4 justify-center items-center'>
            <h2 className='text-2xl text-semibold text-center'>No cluster feed available.</h2>
            <Link
              to={location.state?.from || '/companies'}
              className='btn w-full max-w-[400px]'
            >
              {location.state?.from ? 'Back' : 'Back to Companies'}
            </Link>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className='page-base cluster-feed space-y-4'>
      <section>
        <DynamicCrumbs current="Cluster Feed" />
      </section>

      <section className='page-section'>
        <h1 className='section-header'>Cluster Feed</h1>

        <div className='grid grid-cols-6 grid-rows-2 md:grid-rows-1 gap-2 lg:gap-x-4 lg:gap-y-1 p-2 lg:px-4 lg:pt-7'>
            <input
              type='text'
              value={searchQuery}
              ref={inputRef}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder='Search for clusters'
              className='input col-span-full md:col-span-4 lg:col-span-5 px-4 py-2'
            />

            <div className='col-span-full md:col-span-2 lg:col-span-1 flex gap-1 md:gap-2 border border-[#0561B7] border-opacity-50 dark:bg-[#0561B7] dark:bg-opacity-10 rounded'>
              <button onClick={() => requestSort('total_search_volume')} className='w-full lg:min-w-[70px]' title='Sort by Total Search Volume'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <span className='sr-only'>Total Search Volume</span>
                {getSortArrow('total_search_volume')}
              </button>
              <button onClick={() => requestSort('total_keyword_count')} className='w-full lg:min-w-[70px]' title='Sory by Total Keyword Count'>
                <FontAwesomeIcon icon={faKey} />
                <span className='sr-only'>Total Keyword Count</span>
                {getSortArrow('total_keyword_count')}
              </button>
            </div>
          </div>

        <div className='hidden md:block'>
          <SearchVolBubble data={filteredSearchVol} history={history} clusterId={clusterId} />
        </div>

        <hr className="col-span-full border-[#0561B7] border-opacity-50 border-dotted mx-4" />

        <div>
          <ul className='cluster-list'>
            {sortedClusterFeed.map((item, index) => (
              <li key={index} className='page-section cluster-item'>
                <Link
                title={item.title}
                className='cluster-link'
                to={`/cluster/${item.uuid}`}
                state={{
                  from: `/clusters/${clusterId}`,
                  history: [
                    ...history,
                    {name: "Cluster Feed", path: `/clusters/${clusterId}`},
                  ],
                }}
                >
                  <h2 className='section-header'>
                    <span>{item.title || 'No summary'}</span>
                  </h2>
                  <p className='p-4 pb-0'>
                    <span>{ item.summary || 'No summary'}</span>
                  </p>
                  <div title="Total Search Volume" className='flex justify-end gap-4 px-4 py-2 text-sm'>
                    <div className='flex gap-2 items-center'>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                      <span>{formatFloat(item.total_search_volume, 0)}</span>
                    </div>
                    <div title="Total Keyword Count" className='flex gap-2 items-center'>
                      <FontAwesomeIcon icon={faKey} />
                      <span>{formatFloat(item.total_keyword_count, 0)}</span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Clusters;
