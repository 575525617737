import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import '../Admin.css';

function ForecastLeaderboard() {
  return <>
    <div className="admin-page page-base forecast-leaderboard">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/library-catalog">Library Catalog</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-watermelon-500">
            Forecast Leaderboard
          </li>
        </ol>
      </nav>

      <section className="page-section">
        <h1 className="section-header">Forecast Leaderboard</h1>

        <div className="p-4">
          forecast leaderboard content
        </div>
      </section>
    </div>
  </>
}

export default ForecastLeaderboard;