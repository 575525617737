// react
import { useNavigate, useParams, Link } from "react-router-dom";
import Markdown from 'react-markdown'

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faChartPie, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

// hooks
import useProject from "../../hooks/useProject";
import useBrandAnalysis from "../../hooks/useBrandAnalysis";

// global components
import DynamicCrumbs from "../../components/Breadcrumbs/DynamicCrumbs";
import ErrorDisplay from "../../components/ErrorDisplay/ErrorDisplay.jsx";
import ChartSlider from "../../components/ChartSlider/ChartSlider";
import Loading from "../../components/Loading/Loading";
import RefreshButton from "../../components/RefreshButton/RefreshButton";

import AnnualForecast from "../Project/Visualizations/AnnualForecast/AnnualForecast";

// local components
import ACNUInfo from "./Visualizations/ACNUInfo/ACNUInfo";
import ChartSettings from "./ChartSettings/ChartSettings";
import ForecastTable from "./Visualizations/ForecastTable/ForecastTable";
import ConditionOpportunity from "./Visualizations/ConditionOpportunity/ConditionOpportunity";
import DemographicOpportunity from "./Visualizations/DemographicOpportunity/DemographicOpportunity";
import SearchOpportunity from "./Visualizations/SearchOpportunity/SearchOpportunity";
import SearchSpendRec from "./Visualizations/SearchSpendRec/SearchSpendRec";
import ConversionFunnel from "./Visualizations/ConversionFunnel/ConversionFunnel";
import AnnualForecastByCategory from "./Visualizations/AnnualForecastByCategory/AnnualForecastByCategory";
import TolerabilityChart from "./Visualizations/SafetyTolerability/TolerabilityChart.jsx";
import Warnings from "./Visualizations/SafetyTolerability/Warnings";
import StopUsageChart from "./Visualizations/StopUsageChart/StopUsageChart";

import "./ACNUFeasibility.css";

const ACNUFeasibility = () => {
  const navigate = useNavigate();
  const uuid = useParams().projectId;
  const { project, loading: loadingProject, error: errorProject } = useProject(uuid, navigate);
  const { brandData, loading: loadingBrand, error: errorBrand } = useBrandAnalysis(uuid, navigate);

  if (loadingProject || loadingBrand) {
    return <Loading />;
  }

  if (errorProject || errorBrand) {
    return (
      <div className="acnu-feasibility page-base space-y-4 md:space-y-8">
        <DynamicCrumbs current="ACNU Feasibility" />
        <ErrorDisplay error={errorProject || errorBrand} />
      </div>
    );
  }

  if (!brandData || Object.keys(brandData).length === 0) {
    return <div className='acnu-feasibility page-base space-y-4 md:space-y-8'>
      <DynamicCrumbs current="ACNU Feasibility" />
      <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
        <h1>No brand data available.</h1>;
      </section>
    </div>
  }

  if(!project || Object.keys(project).length === 0) {
    return <div className='acnu-feasibility page-base space-y-4 md:space-y-8'>
      <DynamicCrumbs current="ACNU Feasibility" />
      <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
        <h1>No project data available.</h1>;
      </section>
    </div>
  }

  return (
    <div className='acnu-feasibility page-base space-y-4 md:space-y-8'>
      <section>
        <DynamicCrumbs current="ACNU Feasibility" />

        <div className="page-header">
          <div className="flex flex-col md:flex-row md:gap-4 md:items-center col-span-1">
            <div className="grid grid-rows-2">
              <div className="flex gap-4 items-center">
                <h1 className="grad">{project.name}</h1>
                <Link to={`/project-edit`} state={{ project }}>
                  <FontAwesomeIcon icon={faEdit} />
                </Link>

                <RefreshButton
                  refresh_property="project"
                  project={project}
                  project_eligible_for_analysis={
                    project.eligible_for_analysis
                  }
                />
              </div>

              { project.url && <a href={project.url} target="_blank" rel="noreferrer">
                {project.url}
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faUpRightFromSquare}
                  size="xs"
                />
              </a>}

            </div>

            <div className="acnu-section">
              {project.uuid === "e8d32602-0897-44a4-a14a-5152c1d44fdc" ? (
                <Link
                  to={`/project/${project.uuid}/acnu_dashboard`}
                  state={{ project }}
                >
                  ACNU Dashboard
                  <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                </Link>
              ) : (
                <button disabled>
                  ACNU Dashboard
                  <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                </button>
              )}
            </div>
          </div>

          <ACNUInfo project={project} />
        </div>
      </section>

      <section className="page-section pb-2">
        <h2 className="section-header flex justify-between ">
          ACNU Forecast
          <ChartSettings project={ project } />
        </h2>

        <div>
          <p className="p-4">The following story projects the opportunity associated with converting this medication to an OTC via an ACNU</p>

          <ChartSlider>
              <ConditionOpportunity data={ project?.condition_analysis } />
              <DemographicOpportunity data={ project?.condition_analysis } />
              <SearchOpportunity universe={ project?.keyword_universe } condition_analysis={ project.condition_analysis } />
              <SearchSpendRec data={ project?.spending_recs } />
              <ConversionFunnel data={ project?.keyword_universe } />
              <AnnualForecast data={ project?.keyword_universe } />
          </ChartSlider>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Forecast by Category</h2>
        <div className="chart forecast space-y-4">
          <ChartSlider>
            <AnnualForecastByCategory data={project?.keyword_universe} category_type="Branded" />
            <AnnualForecastByCategory data={project?.keyword_universe} category_type="Condition" />
          </ChartSlider>
        </div>

        <div className="p-2">
          <ForecastTable data={project?.keyword_universe} />
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Diagnosis Requirements</h2>

        <div className="p-4">
          <Markdown disallowedElements={['code', 'pre']}>
            { project?.condition_analysis?.basic_diagnosis_criteria || "No diagnosis criteria available." }
          </Markdown>
        </div>
      </section>

      <section className="page-section safety-tolerability">
        <h2 className="section-header">Safety and Tolerability</h2>

        <div className="info-container">
          <Warnings project={project} />

          <div className="text-summary">
            { brandData && brandData.safety_tolerability_guide 
              ? <Markdown disallowedElements={['code', 'pre']}>{brandData.safety_tolerability_guide}</Markdown>
              : <p className="text-center px-0 py-8 md:p-8">"No safety and tolerability guide available.</p>
            }
          </div>

          <div className="row-start-3 md:col-start-5 md:row-start-1 md:row-span-2 md:col-span-2">
            { brandData?.side_effect_plot && <TolerabilityChart project={ project } data={ brandData.side_effect_plot } /> }
          </div>
        </div>
      </section>

      <section className="page-section stop-use">
        <h2 className="section-header">Stopping Use, Dependence and Withdrawal</h2>

        <div className="p-4 space-y-2">
          {brandData && brandData.stop_usage_guide ? (
            <Markdown disallowedElements={['code', 'pre']}>{brandData.stop_usage_guide}</Markdown>
            ) : (
              <p className="text-center px-0 py-8 md:p-8">
                No stop usage data available.
              </p>
            )}
        </div>

        { brandData?.stop_usage_activity &&
          <StopUsageChart data={brandData?.stop_usage_activity} />
        }
      </section>
    </div>
  );
}

export default ACNUFeasibility;