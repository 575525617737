import { useState } from "react";
import { Link, useParams } from "react-router-dom"
import Markdown from "react-markdown";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../../components/Loading/Loading";
import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";

import useCondition from "./hooks/useCondition";

import '../Admin.css';
import './ConditionProfile.css';

function ConditionProfile() {
  const { conditionId } = useParams();
  const { condition, loading, error } = useCondition(conditionId);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { label: 'Keyword Universe' },
    { label: 'Condition' },
    { label: 'Competition' },
    { label: 'Forecast' },
  ];

  const handleActiveTab = (e) => {
    const tab = Number(e.currentTarget.dataset.index);
    setActiveTab(tab);
  }

  if (loading) return <Loading />

  if (error) {
    return <div className="admin-page page-base condition-profile">
      <ErrorDisplay error={error} />
    </div>
  }

  return <>
    <div className="admin-page page-base condition-profile">
      <nav aria-label="breadcrumbs">
        <ol className="flex gap-2 text-xs md:text-sm items-center">
          <li>
            <Link to="/admin">Admin</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/library-catalog">Library Catalog</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li>
          <Link to="/admin/condition-library">Condition Library</Link>
            <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
          </li>

          <li className="font-bold text-watermelon-500">
          { condition.name }
          </li>
        </ol>
      </nav>

      <section className="page-section">
        <h1 className="section-header">{ condition.name }</h1>

        <Markdown className="p-4 space-y-2">
          { condition.condition_overview || 'Condition overview not available' }
        </Markdown>
      </section>

      <section className="page-section">
        <h2 className="section-header">Interest</h2>

        <div className="tab-group">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`tab bg-opacity-10 ${activeTab === index ? 'active' : ''}`}
              style={{ '--color': activeTab === index ? "#0561B7" : "#CCC" }}
              data-index={index}
              onClick={handleActiveTab}
            >
              <div className="bg" />
              <h3>{tab.label}</h3>
            </button>
          ))}
        </div>

        <div className="tab-content">
          <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`}>
            <h3>Keyword Universe</h3>
          </div>

          <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
            <h3>Condition</h3>
          </div>

          <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
            <h3>Competition</h3>
          </div>

          <div className={`tab-pane ${activeTab === 3 ? 'active' : ''}`}>
            <h3>Forecast</h3>
          </div>
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Diagnosis Requirements</h2>

        <Markdown className="p-4 space-y-2">
          { condition.basic_diagnosis_criteria || 'Diagnosis requirements not available' }
        </Markdown>
      </section>

      <section className="page-section">
        <h2 className="section-header">Condition Keywords</h2>

        <div className="p-4 text-center text-2xl font-bold">
          TBD
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Medications Treating { condition.name }</h2>

        <div className="p-4 text-center text-2xl font-bold">
          TBD
        </div>
      </section>

      <section className="page-section">
        <h2 className="section-header">Clusters</h2>

        <div className="p-4 flex justify-center">
          <Link 
            to={`/clusters/${condition.uuid}`} 
            state={{
              from: `/admin/condition-profile/${condition.uuid}`,
              history: [
                {name: 'Admin', path: '/admin'},
                {name: 'Library Catalog', path: '/admin/library-catalog'},
                {name: 'Condition Library', path: '/admin/condition-library'},
                {name: condition.name, path: `/admin/condition-profile/${condition.uuid}`},
              ]
            }}
            className="btn w-full max-w-[400px]">
              View Clusters
            </Link>
        </div>
      </section>
    </div>
  </>
}

export default ConditionProfile;